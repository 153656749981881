import React, { Component } from 'react';
import {
  Image,
  Heading,
  Paragraph,
  Link,
  Button,
  IconStatusInformationOnlight,
  IconStatusYesThick,
  ModalDialog,
  Section,
  IconNewModal,
  VisuallyHidden,
} from '@piggybank/core';
import './SimpleAccount.css';
import {
  renderUnorderedList,
  renderRepeatedComponent,
  evaluateLikelihood,
  isUnlikely,
  forceFocusToLink,
  renderLikelihoodCopy,
  renderLikelihoodDefinitions,
} from '../../shared/utils';
import BrandContext from '../../shared/brandContext';
import { getTealiumPageDefaults } from '../../shared/tealium';
import { tealiumEvent } from '../../shared/utils';

class SimpleAccount extends Component {
  render() {
    const pageContent = this.context.brand.content.account;
    const accountInformation = this.context.brand.content.accountInformation;
    const iconColours = this.context.brand.settings.iconColours;
    const likelihood = evaluateLikelihood(this.props.values.adjustedOverdraftLimit, this.props.response, pageContent);
    return (
      <Section marginBottom={5} id='simpleAccountInfo'>
        <Heading level={2}>{accountInformation.displayName}</Heading>
        <div className='accountResult'>
          <div className='cardImage'>
            <Image src={accountInformation.cardImage} alt='' marginBottom={0} />
          </div>
          <div className='likelihood'>
            <div className='likelihoodIcon'>
              {isUnlikely(likelihood, pageContent) ? (
                <IconStatusInformationOnlight inline style={{ color: iconColours.likelihood }} />
              ) : (
                <IconStatusYesThick inline style={{ color: '#00847F' }} />
              )}
            </div>
            <Paragraph id='likelihoodResult'>
              <span style={{ fontWeight: 'bold', display: 'contents' }}>{likelihood}</span>{' '}
              {renderLikelihoodCopy(likelihood, this.props.response, this.props.values, pageContent)}
            </Paragraph>
          </div>
          <div className='likelihoodModal'>
            <ModalDialog
              data-bdd='likelihoodModal'
              alert
              title={pageContent.likelihoodModal.title}
              getApplicationNode={() => document.getElementById('root')}
              onOpen={() => {
                tealiumEvent('link', {
                  ...getTealiumPageDefaults(['results']),
                  ...{
                    event_action: 'onsite',
                    event_content: `what does ${likelihood.toLowerCase()} mean`,
                    funnel_step_name: 'results',
                  },
                });
              }}
              onClose={() => forceFocusToLink(this.likelihoodLink)}
              renderOpenButton={({ openDialog }) => (
                <Paragraph marginBottom={0}>
                  {pageContent.likelihoodModal.preLink}{' '}
                  <Link
                    data-bdd='likelihoodModalLink'
                    href='#'
                    ref={(likelihoodLink) => (this.likelihoodLink = likelihoodLink)}
                    onClick={openDialog}
                    style={{ fontWeight: 'bold' }}
                    iconSlotRight={<IconNewModal inline />}
                  >
                    {likelihood.toLowerCase()} {pageContent.likelihoodModal.postLink}
                    <VisuallyHidden>Opens a modal dialog</VisuallyHidden>
                  </Link>
                </Paragraph>
              )}
            >
              {({ closeDialog }) => (
                <>
                  {renderRepeatedComponent(pageContent.likelihoodModal.copy, Paragraph)}
                  {renderUnorderedList(pageContent.likelihoodModal.definitions.map(renderLikelihoodDefinitions), {
                    spacing: 2,
                  })}
                  {pageContent.likelihoodModal.creditScoring && (
                    <Paragraph>
                      {pageContent.likelihoodModal.creditScoring.preLinkCopy}
                      <Link external href={pageContent.likelihoodModal.creditScoring.link}>
                        {pageContent.likelihoodModal.creditScoring.linkCopy}
                      </Link>
                    </Paragraph>
                  )}
                </>
              )}
            </ModalDialog>
          </div>
        </div>

        <Paragraph marginBottom={3}>{pageContent.copy}</Paragraph>

        <Button
          elementType='a'
          external
          href={accountInformation.url}
          style={{ width: '100%' }}
          onClick={() => {
            tealiumEvent('link', {
              ...getTealiumPageDefaults(['results']),
              ...{
                event_action: 'button',
                event_content: 'learn more and apply',
                funnel_step_name: 'results',
                account_type: '',
                tool_event: 'completed',
              },
            });
          }}
        >
          {pageContent.applyButton}
          <VisuallyHidden>{accountInformation.displayName}</VisuallyHidden>
        </Button>
      </Section>
    );
  }
}

SimpleAccount.contextType = BrandContext;
export default SimpleAccount;
