import React from 'react';
import advanceDebit from '../media/cardImages/advance_debit.png';
import massDebit from '../media/cardImages/mass_debit.png';
import premierDebit from '../media/cardImages/premier_debit.png';

export default [
  {
    accountType: 'student',
    displayName: 'Student Bank Account',
    cardImage: massDebit,
    cardImageDescription: 'HSBC Debit Card Image',
    features: [
      'no monthly account fee',
      'discounts, tickets and Student Credit Card up to £500 (subject to status)',
      <>
        Representative example: <b>0% EAR variable</b>, giving a representative rate of{' '}
        <b>0% APR variable (assumed arranged overdraft £1,200)</b>.
      </>,
    ],
    criteria: ['To be eligible for an HSBC Student Account you’ll need to confirm that:'],
    criteriaList: ['you do not hold a student account elsewhere', 'you are studying, or intending to study'],
    criteriaSubList: [
      'a full time qualifying course or a apprenticeship (level 2-7) with a UK university / college / apprentice learning provider)',
      'postgraduates must have completed an undergraduate degree course in the UK in the last three years. To confirm this we must see proof eg graduation certificate / diploma or letter from university / college ',
    ],
    url: 'https://www.hsbc.co.uk/current-accounts/products/student/#eligibility',
  },
  {
    accountType: 'graduate',
    displayName: 'Graduate Bank Account',
    cardImage: massDebit,
    cardImageDescription: 'HSBC Debit Card Image',
    features: [
      'no monthly account fee',
      'Visa debit card and attractive Regular Saver Rates',
      <>
        Representative example <b>(assumed arranged overdraft of £1,200)</b>: Year 1: <b>0% EAR</b> on the first £1,500,{' '}
        <b>39.9% EAR variable</b> on anything above that, giving a representative rate of <b>0% APR variable</b>. Year
        2: <b>0% EAR</b> on the first £1,000, <b>39.9% EAR variable</b> thereafter, giving a representative rate of{' '}
        <b>5.8% APR variable</b>.
      </>,
    ],
    criteria: [
      'To be eligible for HSBC Graduate Account you need to confirm that you graduated from the university within the last 2 years.',
    ],
    url: 'https://www.hsbc.co.uk/current-accounts/products/graduate/#eligibility',
  },
  {
    accountType: 'premier',
    displayName: 'Premier Bank Account',
    cardImage: premierDebit,
    cardImageDescription: 'HSBC Debit Card Image',
    features: [
      'no monthly account fee',
      'worldwide travel cover and relationship service 24/7',
      <>
        Representative example: <b>0% EAR variable</b> on the first £500, <b>39.9% EAR (variable)</b> on anything above
        that, giving a representative rate of <b>21.8% APR (variable) (assumed arranged overdraft £1,200)</b>.
      </>,
    ],
    criteria: [
      'To join HSBC Premier, you’ll need to pay your annual income into your Premier account, and confirm that:',
    ],
    criteriaList: [
      'within 6 months, you will have savings or investments of at least £50,000 with HSBC UK',
      'have an individual annual income of at least £75,000 and one of the following with HSBC UK: a mortgage, an investment, life insurance or a protection product',
      'hold and qualify for HSBC Premier in another country',
    ],
    url: 'https://www.hsbc.co.uk/current-accounts/products/premier/day-to-day-banking/bank-account/#eligibility',
  },
  {
    accountType: 'advance',
    displayName: 'Advance Bank Account',
    cardImage: advanceDebit,
    cardImageDescription: 'HSBC Debit Card Image',
    features: [
      'no monthly account fee',
      'exclusive discount and offers and dedicated Advance Specialists 24/7',
      <>
        Representative example: <b>0% EAR variable</b> on the first £25, <b>39.9% EAR (variable)</b> on anything above
        that, giving a representative rate of <b>38.9% APR (variable) (assumed arranged overdraft £1,200)</b>.
      </>,
    ],
    criteria: [
      "To join HSBC Advance you’ll need to confirm that you'll pay at least £1,750 a month into the account, or £10,500 every 6 months. This does not include money transferred from any other sole or joint personal accounts held with HSBC UK Bank.",
    ],
    url: 'https://www.hsbc.co.uk/current-accounts/products/advance/#eligibility',
  },
  {
    accountType: 'bank',
    displayName: 'Bank Account',
    cardImage: massDebit,
    cardImageDescription: 'HSBC Debit Card Image',
    features: [
      'no monthly account fee',
      'Visa offers and £300 daily ATM cash withdrawals',
      <>
        Representative example: <b>0% EAR variable</b> on the first £25, <b>39.9% EAR (variable)</b> on anything above
        that, giving a representative rate of <b>38.9% APR (variable) (assumed arranged overdraft £1,200)</b>.
      </>,
    ],
    criteria: [
      "To be eligible for an HSBC Bank Account, you’ll need to confirm that you'll pay at least £500 a month into the account, or £3,000 every 6 months. This doesn’t apply if you’re under 24 or over 65 years old.",
    ],
    url: 'https://www.hsbc.co.uk/current-accounts/products/bank-account/#eligibility',
  },
  {
    accountType: 'basic',
    displayName: 'Basic Bank Account',
    cardImage: massDebit,
    cardImageDescription: 'HSBC Debit Card Image',
    features: ['no monthly account fee', 'banking services: 24/7 internet, telephone and mobile banking'],
    criteria: [
      "Our Basic Bank Account is designed only for customers who don't qualify for our other accounts (for example because they are bankrupt, in financial difficulty or have a poor credit history).",
      "To be eligible you can't already hold a UK bank or payment account.",
    ],
    url: 'https://www.hsbc.co.uk/current-accounts/products/basic-bank-account/#eligibility',
  },
];
