export default {
  browserTitle: 'Overdraft Eligibility - Welcome',
  pageTitle: 'Check your overdraft eligibility',
  pageSubTitle: 'This will have no impact on your credit rating.',
  expander: {
    title: 'What is an eligibility check?',
    copy: [
      'The overdraft eligibility check will give you an indication of whether or not you will be accepted for an overdraft.',
      'The tool works by conducting a quotation search on your credit file, you will be able to see the search on your file but no other organisations will – this means it won’t affect your credit rating.',
      "The results of this overdraft eligibility check are indicative and will not constitute an offer to provide credit. If you decide to continue with your application, we'll carry out a full credit reference agency and affordability check. We reserve the right to decline any application for credit facilities or to offer to lend on different terms.",
      'The output of the tool is dependent on the accuracy of the information that you provide us with.',
    ],
  },
  sectionTitle: 'Please check the following',
  criteria: ['You’re aged 18 years or above and are a permanent UK resident', "You don't require a joint account"],
  cta: 'Confirm and start',
};
