import { tealiumEvent } from '../shared/utils';
const tealiumDefaults = {
  // Don't define page_url or page_name here, so that Timeout is able
  // to use the values of these from the page it is on.

  site_section: 'pws',
  page_language: 'en',
  page_customer_group: 'general',
  customer_group: 'general',
  page_business_line: 'rbwm',
  page_security_level: '0',
  funnel_name: 'overdraft eligibility',

  event_category: 'content',
  event_subcategory: '',
  event_action: '',
  event_content: '',
  page_type: 'tool',
  product_category: 'accounts',
  page_category: 'account services',
  page_subcategory: 'overdraft eligibility',
  funnel_step: '',
  funnel_step_name: '',
  form_field_1: '',
  account_type: '',
  tool_name: 'eligibility tool',
  tool_event: '',
  error_type: '',
};

const getTealiumPageDefaults = (page) => ({
  ...tealiumDefaults,
  ...{
    page_url: `/account-services/overdraft-eligibility/${page
      .map((v) => v.replace(/ /g, '-'))
      .reduce((r, v) => r + '/' + v)}`,
    page_name: `pws:account services:overdraft eligibility:${page.reduce((r, v) => r + ':' + v)}`,
  },
});

const tealiumHandleFormSubmit = (values) => {
  tealiumEvent('link', {
    ...getTealiumPageDefaults(['personal details']),
    ...{
      event_action: 'button',
      event_content: 'check your eligibility',
      funnel_step_name: 'personal details',
      tool_event: 'interaction',
    },
  });

  tealiumEvent('link', {
    ...getTealiumPageDefaults(['personal details']),
    ...{
      event_action: 'dropdown',
      event_content: 'marital status: ' + values.maritalStatus.toLowerCase(),
      funnel_step_name: 'personal details',
    },
  });

  tealiumEvent('link', {
    ...getTealiumPageDefaults(['personal details']),
    ...{
      event_action: 'radio',
      event_content: 'residential status: ' + values.residentialStatus.toLowerCase(),
      funnel_step_name: 'personal details',
    },
  });

  tealiumEvent('link', {
    ...getTealiumPageDefaults(['personal details']),
    ...{
      event_action: 'dropdown',
      event_content: 'employment status: ' + values.employmentStatus.toLowerCase(),
      funnel_step_name: 'personal details',
    },
  });

  tealiumEvent('link', {
    ...getTealiumPageDefaults(['personal details']),
    ...{
      event_action: 'textbox',
      event_content: 'overdraft amount',
      funnel_step_name: 'personal details',
      form_field_1: values.overdraft,
    },
  });

  if (values.occupation) {
    tealiumEvent('link', {
      ...getTealiumPageDefaults(['personal details']),
      ...{
        event_action: 'dropdown',
        event_content: 'occupation: ' + values.occupation.toLowerCase(),
        funnel_step_name: 'personal details',
      },
    });
  }

  if (values['previous-address-line-1']) {
    tealiumEvent('link', {
      ...getTealiumPageDefaults(['personal details']),
      ...{
        event_action: 'onsite',
        event_content: 'enter your previous address',
        funnel_step_name: 'personal details',
      },
    });

    tealiumEvent('link', {
      ...getTealiumPageDefaults(['personal details']),
      ...{
        event_action: 'dropdown',
        event_content: values.previousCountry.toLowerCase(),
        funnel_step_name: 'personal details',
      },
    });
  }

  if (values.hasSavingsOrInvestments) {
    tealiumEvent('link', {
      ...getTealiumPageDefaults(['personal details']),
      ...{
        event_action: 'radio',
        event_content: '50k savings or investment in 6 month: ' + values.hasSavingsOrInvestments,
        funnel_step_name: 'personal details',
      },
    });
  }

  if (values.hasHsbcProduct) {
    tealiumEvent('link', {
      ...getTealiumPageDefaults(['personal details']),
      ...{
        event_action: 'radio',
        event_content: 'have product in hsbc uk: ' + values.hasHsbcProduct,
        funnel_step_name: 'personal details',
      },
    });
  }
};

export { tealiumDefaults, getTealiumPageDefaults, tealiumHandleFormSubmit };
