import businessType from './businessType.json';
import employmentStatus from './employmentStatus.json';
import maritalStatuses from './maritalStatus.json';
import occupation from './occupation.json';
import titles from './title.json';
import payFrequency from './payFrequency.json';
import residentialStatus from './residentialStatus.json';
import countries from './countries.json';
import ucasCodes from './ucasCodes.json';

export default {
  businessType: businessType,
  employmentStatus: employmentStatus,
  maritalStatuses: maritalStatuses,
  occupation: occupation,
  titles: titles,
  payFrequency: payFrequency,
  residentialStatus: residentialStatus,
  countries: countries,
  ucasCodes: ucasCodes,
};
