export default {
  countdown: {
    title: 'Need more time?',
    copy: [
      'For your security, your session will shortly timeout due to inactivity.  If it times out, we will remove all the personal details you entered.',
    ],
    timeRemainingBefore: 'Time remaining:',
    timeRemainingAfter: 'seconds',
    buttonText: 'Continue eligibility check',
  },
  timedOut: {
    browserTitle: 'Overdraft Eligibility - Timeout',
    heading: 'Timeout',
    copy: [
      'For your security, your session has timed out.',
      'This happens if you leave it idle for more than 10 minutes.',
      'We have removed all the personal details you entered.',
    ],
    buttonText: 'Start again',
  },
};
