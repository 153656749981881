export default {
  browserTitle: 'Overdraft Eligibility - Personal details',
  pageTitle: 'Overdraft eligibility checker',
  pageIntro:
    'Find out about the likelihood of being able to get a certain overdraft.  All questions are mandatory unless indicated.',
  overdraft: {
    heading: 'Overdraft amount',
    maxAmount: 3000,
    trackLabels: {
      0: '£0',
      3000: '£3,000',
    },
    hint: 'Enter your overdraft amount in multiples of £50',
    label: 'Please add the arranged overdraft limit you’d like to check (maximum £3,000)',
    errorRequired: 'Enter an arranged overdraft amount',
    errorMax: 'Enter an amount of £3,000 or less',
    errorInvalid: 'Overdraft amount must be in multiples of £50',
  },
  personalDetails: {
    heading: { copy: 'Personal Details', accentBar: true },
    title: {
      label: 'Title',
      errorBlank: 'Select your title',
    },
    forenames: {
      label: 'First names',
      hint: 'As they appear on your identity documents including any middle names.',
      errorBlank: 'Enter all first names and any middle names you have',
      errorMin: 'Your first names and middle names must have at least 2 characters',
      errorInvalid: "Enter your first names and middle names using only the characters a-z, - and '",
    },
    surname: {
      label: 'Surname or Family name',
      hint: 'As it appears on your identity documents.',
      errorBlank: 'Enter surname or family name',
      errorMin: 'Your surname or family name must have at least 2 characters',
      errorInvalid: "Enter your surname or family name using only the characters a-z, - and '",
    },
    dateOfBirth: {
      label: 'What is your date of birth?',
      errorBlank: 'Enter your date of birth',
      errorAboveMaxAge: 'Check your date of birth is correct',
      errorBelowMinAge: "If you're under 18 we can't offer you this product",
      errorInvalid: 'Enter your date of birth in the format DD MM YYYY',
    },
    maritalStatus: {
      label: 'Marital status',
      errorBlank: 'Select your marital status',
    },
    dependents: {
      label: 'Number of dependent children',
      hint: 'The number of children living in your household who are financially dependent on you.',
      errorBlank: 'Dependent children must be a number between 0 and 99',
    },
  },
  address: {
    heading: { copy: 'Current UK residential address' },
    residentialStatus: {
      label: 'Residential status',
      errorBlank: 'Select your residential status',
    },
    currentDateMoved: {
      label: 'When did you move to this address?',
      hint: "We need to understand where you've lived for the past 3 years",
      errors: {
        blank: 'Enter the date you moved to this address in the format MM YYYY',
        invalid: 'Enter the date you moved to this address in the format MM YYYY',
        dob: "You've entered a move date that's before your birth date.  Check and re-enter",
        min: 'Check the date is correct',
        max: "The date you've entered is in the future, please amend it",
      },
    },
    previousDateMoved: {
      label: 'When did you move to this address?',
      errors: {
        blank: 'Enter the date you moved to this address in the format MM YYYY',
        invalid: 'Enter the date you moved to this address in the format MM YYYY',
        dob: "You've entered a move date that's before your birth date.  Check and re-enter",
        current: "You've entered a move date that's after your current address.  Check and re-enter",
        min: 'Check the date is correct',
        max: "The date you've entered is in the future, please amend it",
      },
    },
    previousAddress: 'Where did you live before',
    previousCountry: 'Country / region',
    current: {
      search: {
        label: 'Find your UK address',
        hint: 'Enter your address or postcode',
      },
      errors: {
        noResults:
          "We can't find that address. Please check it and try again. If it still doesn't appear, enter it manually",
        noService: "Sorry, we can't look up addresses at the moment. Please enter it manually",
        blank: 'Enter your current address',
        'current-address-line-1': {
          blank: 'Enter your building number or name',
          invalid: 'Enter your building number or name using only alphanumeric characters and basic punctuation',
        },
        'current-address-line-2': {
          blank: 'Enter your street name',
          invalid: 'Enter street using only alphanumeric characters and basic punctuation',
        },
        'current-address-line-3': {
          blank: 'Enter your town or city',
          invalid: 'Enter town or city using only alphanumeric characters and basic punctuation',
        },
        'current-postcode': {
          blank: 'Enter your postcode',
          format: 'Check your postcode is correct',
          ciiom: 'We are not able to accept applications from the Channel Islands and the Isle of Man',
        },
      },
      enterManually: 'Enter your address manually',
      editButton: {
        label: 'Edit',
        hidden: 'your current address',
      },
      addressLabel: 'Your current address',
      fields: {
        line1: 'Building number or name',
        line2: 'Street',
        line3: 'Town or city',
        postcode: 'Postcode',
      },
    },
    previousUk: {
      search: {
        label: 'Find your address',
        hint: 'Enter your address or postcode',
        errors: {
          blank: 'Enter the date you moved to this address in the format MM YYYY',
          invalid: 'Enter the date you moved to this address in the format MM YYYY',
          dob: "You've entered a move date that's before your birth date.  Check and re-enter",
          min: 'Check the date is correct',
          max: "The date you've entered is in the future, please amend it",
        },
      },
      errors: {
        noResults:
          "We can't find that address. Please check it and try again. If it still doesn't appear, enter it manually",
        noService: "Sorry, we can't look up addresses at the moment. Please enter it manually",
        blank: 'Enter your previous address',
        'previous-address-line-1': {
          blank: 'Enter your building number or name',
          invalid: 'Enter your building number or name using only alphanumeric characters and basic punctuation',
        },
        'previous-address-line-2': {
          blank: 'Enter your street name',
          invalid: 'Enter street using only alphanumeric characters and basic punctuation',
        },
        'previous-address-line-3': {
          blank: 'Enter your town or city',
          invalid: 'Enter town or city using only alphanumeric characters and basic punctuation',
        },
        'previous-postcode': {
          blank: 'Enter your postcode',
          format: 'Check your postcode is correct',
        },
      },
      enterManually: 'Enter your address manually',
      editButton: {
        label: 'Edit',
        hidden: 'your previous address',
      },
      addressLabel: 'Your previous address',
      fields: {
        line1: 'Building number or name',
        line2: 'Street',
        line3: 'Town or city',
        postcode: 'Postcode',
      },
    },
    previousOverseas: [
      {
        label: 'Address line 1',
        errors: {
          blank: 'Enter address Line 1',
          invalid: 'Enter address line 1 using only alphanumeric characters and basic punctuation',
        },
      },
      {
        label: 'Address line 2',
        errors: {
          blank: 'Enter address Line 2',
          invalid: 'Enter address line 2 using only alphanumeric characters and basic punctuation',
        },
      },
      {
        label: 'Address line 3 (optional)',
        errors: {
          invalid: 'Enter address line 3 using only alphanumeric characters and basic punctuation',
        },
      },
      {
        label: 'Address line 4 (optional)',
        errors: {
          invalid: 'Enter address line 4 using only alphanumeric characters and basic punctuation',
        },
      },
      {
        label: 'Address line 5 (optional)',
        errors: {
          invalid: 'Enter address line 5 using only alphanumeric characters and basic punctuation',
        },
      },
    ],
  },
  employment: {
    heading: 'Employment',
    employmentStatus: {
      label: 'Select an option that best describes your status',
      errorBlank: 'Select your employment status',
    },
    occupation: {
      label: 'Occupation',
      errorBlank: 'Select your occupation',
    },
    businessType: {
      label: 'Business type',
      errorBlank: 'Select a business type',
    },
  },
  income: {
    heading: 'Income',
    annualIncome: {
      label: 'Total annual income (before tax and other deductions)',
      errorBlank: 'Enter your annual income',
    },
    payFrequency: {
      placeholder: 'Please select',
      zeroIncomePlaceholder: 'N/A',
      employedLabel: 'How often are you paid?',
      employedErrorBlank: "Select how often you're paid",
      mainIncomeLabel: 'How often do you receive your main income?',
      mainIncomeErrorBlank: 'Select how often you receive your main income',
    },
  },
  importantInformation: {
    heading: 'How we use your personal information',
    preLinkCopy: [
      'We’ll use your personal information to check your eligibility for the arranged overdraft amount you requested.',
      'This will give you an indication of the possibility there is for us to be able to offer you an overdraft of a certain amount. We also use the information to make sure the performance and accuracy of the checker is monitored effectively.',
    ],
    privacyPolicyCopy: 'Please be assured that your data will be kept confidential and used in accordance with our ',
    privacyPolicyLink: 'Privacy Notice.',
    privacyPolicyURL: 'https://www.firstdirect.com/content/dam/fsdt/en/documents/pdf/privacy_notice.pdf',
  },
  ctaPrevious: 'Back',
  ctaSubmit: 'Check your eligibility',
};
