export default {
  browserTitle: 'Overdraft Eligibility - Decline',
  pageTitle: 'You’re not eligible for HSBC accounts or overdrafts',
  copy: [
    'Unfortunately, based on the information provided for this overdraft eligibility check, you’re not eligible for HSBC accounts or overdrafts.',
    'You can still choose to apply for an HSBC account. However, if your application is declined your credit rating could be affected.',
  ],
  button: 'Go to HSBC website',
  url: 'https://www.hsbc.co.uk/',
};
