import React, { Component } from 'react';
import { FormLayout, Heading, Expander, Paragraph } from '@piggybank/core';
import { Navigation } from '@piggybank/form';
import { renderRepeatedComponent, renderUnorderedList } from '../../shared/utils';
import BrandContext from '../../shared/brandContext';
import { getTealiumPageDefaults } from '../../shared/tealium';
import { tealiumEvent, tealiumView } from '../../shared/utils';

const tealiumLoaded = () => {
  if (window.TMS) {
    tealiumView({
      ...getTealiumPageDefaults(['welcome']),
      ...{
        event_category: '',
        funnel_step: '1',
        funnel_step_name: 'welcome',
        tool_name: '',
      },
    });
  } else {
    setTimeout(() => tealiumLoaded(), 1000);
  }
};

class LandingPage extends Component {
  componentDidMount() {
    document.title = this.context.brand.content.landingPage.browserTitle;
    tealiumLoaded();
  }

  render() {
    const content = this.context.brand.content.landingPage;

    return (
      <FormLayout marginBottom={6}>
        <Heading level={1}>{content.pageTitle}</Heading>
        <Paragraph lead marginBottom={5}>
          {content.pageSubTitle}
        </Paragraph>
        <Expander
          title={content.expander.title}
          marginBottom={6}
          onOpen={() =>
            tealiumEvent('link', {
              ...getTealiumPageDefaults(['welcome']),
              ...{
                event_action: 'accordion',
                event_content: 'what is an eligibility check?',
                funnel_step_name: 'welcome',
                tool_name: '',
              },
            })
          }
        >
          {renderRepeatedComponent(content.expander.copy, Paragraph)}
        </Expander>
        <Heading level={2} accentBar>
          {content.sectionTitle}
        </Heading>
        {renderUnorderedList(content.criteria, {
          type: 'tick',
          spacing: 4,
          marginBottom: 6,
        })}
        <Navigation
          id='next'
          textMap={{ next: content.cta }}
          onNext={(next, rest) => {
            tealiumEvent('link', {
              ...getTealiumPageDefaults(['welcome']),
              ...{
                event_category: 'tool',
                event_action: 'start',
                event_content: '',
                funnel_step_name: 'welcome',
                tool_event: 'interaction',
              },
            });

            next(rest);
          }}
        />
      </FormLayout>
    );
  }
}

LandingPage.contextType = BrandContext;

export default LandingPage;
