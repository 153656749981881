import React, { Component } from 'react';
import { FormLayout, Heading, Paragraph, HorizontalRule, Section } from '@piggybank/core';
import { Navigation, Form, FormFeedback } from '@piggybank/form';
import { createCustomerDetailsSchema } from '../../shared/validation-schema';
import EmploymentDetails from '../../components/EmploymentDetails';
import AddressDetails from '../../components/AddressDetails';
import { PersonalDetails } from '@originations/pattern-library';
import OverdraftDetails from '../../components/OverdraftDetails';
import Income from '../../components/Income/Income';
import FinancialDetails from '../../components/FinancialDetails';
import ImportantInformation from '../../components/ImportantInformation';
import BrandContext from '../../shared/brandContext';
import { getTealiumPageDefaults } from '../../shared/tealium';
import { tealiumView } from '../../shared/utils';

const initialValues = {
  overdraft: '0',
  title: '',
  forenames: '',
  surname: '',
  dateOfBirth: '',
  maritalStatus: '',
  dependents: '',
  employmentStatus: '',
  ucasCode: '',
  cantFindUcasCode: false,
  recentGraduate: 'no',
  graduationYear: '',
  occupation: '',
  businessType: '',
  annualIncome: '',
  payFrequency: '',
  hasSavingsOrInvestments: '',
  hasHsbcProduct: '',
  'current-address-search': '',
  'current-paf-match': undefined,
  'current-address-line-1': '',
  'current-address-line-2': '',
  'current-address-line-3': '',
  'current-postcode': '',
  residentialStatus: '',
  currentDateMoved: '',
  previousCountry: 'GB',
  'previous-address-search': '',
  'previous-paf-match': undefined,
  'previous-address-line-1': '',
  'previous-address-line-2': '',
  'previous-address-line-3': '',
  'previous-address-line-4': '',
  'previous-address-line-5': '',
  'previous-postcode': '',
  previousDateMoved: '',
};

class CustomerDetails extends Component {
  componentDidMount() {
    document.title = this.context.brand.content.customerDetails.browserTitle;

    tealiumView({
      ...getTealiumPageDefaults(['personal details']),
      ...{
        event_category: '',
        funnel_step: '2',
        funnel_step_name: 'personal details',
        tool_name: 'eligibility tool',
      },
    });
  }
  render() {
    const customerDetails = this.context.brand.content.customerDetails;
    const lookups = this.context.brand.lookups;
    return (
      <FormLayout marginBottom={6}>
        <Section>
          <Heading level={1}>{customerDetails.pageTitle}</Heading>
          <Paragraph lead>{customerDetails.pageIntro}</Paragraph>
          <HorizontalRule />
        </Section>
        <Form initialValues={initialValues} validationSchema={createCustomerDetailsSchema(customerDetails)}>
          {({ values, setFieldValue }) => (
            <>
              <FormFeedback />
              <Section>
                <OverdraftDetails />
              </Section>
              <Section>
                <PersonalDetails
                  content={customerDetails.personalDetails}
                  titles={lookups.titles}
                  maritalStatuses={lookups.maritalStatuses}
                  dependentsLength={2}
                />
              </Section>
              <Section>
                <AddressDetails setFieldValue={setFieldValue} values={values} />
              </Section>
              <Section>
                <EmploymentDetails values={values} setFieldValue={setFieldValue} />
              </Section>
              <Section>
                <Income values={values} setFieldValue={setFieldValue} />
              </Section>
              {customerDetails.financialDetails && (
                <Section marginBottom={5}>
                  <FinancialDetails values={values} />
                </Section>
              )}
              <Section>
                <ImportantInformation />
              </Section>
              <Navigation
                id='next'
                isLastPage
                textMap={{
                  submit: customerDetails.ctaSubmit,
                  previous: customerDetails.ctaPrevious,
                }}
              />
            </>
          )}
        </Form>
      </FormLayout>
    );
  }
}

CustomerDetails.contextType = BrandContext;

export default CustomerDetails;
