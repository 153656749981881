import React from 'react';
import { LogoFirstDirect } from '@piggybank/core';

export default {
  logo: LogoFirstDirect,
  footerText: (
    <>
      <b>first direct</b> is a division of HSBC UK Bank plc. © HSBC Group {new Date().getFullYear()}. All rights
      reserved.
    </>
  ),
};
