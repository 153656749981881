import React, { Component } from 'react';
import { Heading, FormLayout, Paragraph, Button, Section } from '@piggybank/core';
import BrandContext from '../../shared/brandContext';
import { getTealiumPageDefaults } from '../../shared/tealium';
import { tealiumView } from '../../shared/utils';

class ErrorPage extends Component {
  componentDidMount() {
    document.title = this.context.brand.content.errorPage.browserTitle;

    tealiumView({
      ...getTealiumPageDefaults(['error']),
      ...{
        event_category: 'error',
        event_action: 'system',
        event_content: this.context.brand.content.errorPage.pageTitle,
        page_type: 'error',
        product_category: '',
        funnel_step_name: 'error',
        tool_name: '',
      },
    });

    this.heading.focus();
  }

  render() {
    const content = this.context.brand.content.errorPage;
    const errorIcon = this.context.brand.settings.icons.error;
    const ErrorIconComponent = errorIcon.icon;
    return (
      <FormLayout marginBottom={6}>
        <ErrorIconComponent width={errorIcon.width} style={errorIcon.style} />

        <Heading level={1} marginBottom={4} ref={(heading) => (this.heading = heading)}>
          {content.pageTitle}
        </Heading>

        <Section>
          <Paragraph>{content.copy}</Paragraph>
        </Section>

        <Button elementType='a' href='/'>
          {content.cta}
        </Button>
      </FormLayout>
    );
  }
}

ErrorPage.contextType = BrandContext;

export default ErrorPage;
