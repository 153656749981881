import accountInformation from './accountInformation';
import app from './app';
import applicationComplete from './applicationComplete';
import account from './account';
import customerDetails from './customerDetails';
import landingPage from './landingPage';
import loadingPage from './loadingPage';
import declinePage from './declinePage';
import timeout from './timeout';
import errorPage from './errorPage';

export default {
  app: app,
  customerDetails: customerDetails,
  applicationComplete: applicationComplete,
  account: account,
  accountInformation: accountInformation,
  landingPage: landingPage,
  loadingPage: loadingPage,
  timeout: timeout,
  declinePage: declinePage,
  errorPage: errorPage,
};
