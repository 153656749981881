import * as yup from 'yup';
import { DateTime, Interval } from 'luxon';
import { isEmployedOrSelfEmployed } from './utils';

const minDate = (years) => {
  const event = new Date();
  event.setFullYear(event.getFullYear() - years);
  return event;
};

const nameRegex = /^( *)([ '-]{0,1}[a-zA-Z]{1}[ '-]{0,1})+( *)$/;

const maxValidDate = new Date();
const minValidDate = '1900-01-01';
const addressRegex = /^[0-9a-zA-Z,./"&amp;()\s\-\\'!]+$/;
const postCodeRegex = /^[a-zA-Z]{1,2}[0-9][a-zA-Z0-9]? ?[0-9][a-zA-Z]{2}$/;
const isLessThanThreeYears = (moveDate) => Interval.fromDateTimes(new Date(moveDate), new Date()).length('years') < 3;

const getYear = (years) => new Date().getFullYear() - years;
const payFrequencyError = (employmentStatus, payFrequency) =>
  isEmployedOrSelfEmployed(employmentStatus) ? payFrequency.employedErrorBlank : payFrequency.mainIncomeErrorBlank;

const transformToValidDate = (date, originalValue) => {
  // Check for invalid dates like 31st of September using Luxon
  // because the Javascript Date constructor rolls the dates forward.
  // e.g.new Date("2019-31-09") becomes 1st October 2019
  // See https://github.com/jquense/yup#extending-schema-types
  if (yup.date().isType(date)) {
    return date;
  }

  return DateTime.fromISO(originalValue).isValid ? new Date(originalValue) : new Date('');
};

const validateAddress = (errors) => yup.string().required(errors.blank).matches(addressRegex, errors.invalid);

const validateOptionalAddress = (errors) =>
  yup.string().matches(addressRegex, {
    message: errors.invalid,
    excludeEmptyString: true,
  });

const validateCurrentAddress = (errors) =>
  yup.string().when('current-paf-match', (pafMatch) => {
    if (pafMatch === false) {
      return validateAddress(errors);
    }
  });

const validatePreviousAddress = (errorsUk, errorsOverseas, overseasOptional = false) =>
  yup
    .string()
    .when(
      ['currentDateMoved', 'previousCountry', 'previous-paf-match'],
      (currentDateMoved, previousCountry, pafMatch) => {
        if (isLessThanThreeYears(currentDateMoved)) {
          if (previousCountry === 'GB') {
            if (errorsUk && pafMatch === false) {
              return validateAddress(errorsUk);
            }
          } else {
            return overseasOptional ? validateOptionalAddress(errorsOverseas) : validateAddress(errorsOverseas);
          }
        }
      }
    );

const validatePostcode = (errors) => yup.string().required(errors.blank).matches(postCodeRegex, errors.format);

const validateDateMoved = (dateMoved, dateOfBirth) =>
  dateMoved >= new Date(dateOfBirth.getYear(), dateOfBirth.getMonth(), 1);

const createCommonFieldsSchema = (content) => {
  const currentAddressErrors = content.address.current.errors;
  const previousUKAddressErrors = content.address.previousUk.errors;
  const previousOverseas = content.address.previousOverseas;
  return yup.object().shape({
    overdraft: yup
      .number()
      .required(content.overdraft.errorRequired)
      .max(content.overdraft.maxAmount, content.overdraft.errorMax)
      .test('isAMultipleOf50', content.overdraft.errorInvalid, (value) => value % 50 === 0),
    title: yup.string().required(content.personalDetails.title.errorBlank),
    forenames: yup
      .string()
      .required(content.personalDetails.forenames.errorBlank)
      .min(2, content.personalDetails.forenames.errorMin)
      .matches(nameRegex, content.personalDetails.forenames.errorInvalid),
    surname: yup
      .string()
      .required(content.personalDetails.surname.errorBlank)
      .min(2, content.personalDetails.surname.errorMin)
      .matches(nameRegex, content.personalDetails.surname.errorInvalid),
    dateOfBirth: yup
      .date()
      .transform((date, originalValue) => transformToValidDate(date, originalValue))
      .typeError(content.personalDetails.dateOfBirth.errorInvalid)
      .required(content.personalDetails.dateOfBirth.errorBlank)
      .max(minDate(18), content.personalDetails.dateOfBirth.errorBelowMinAge)
      .min(minValidDate, content.personalDetails.dateOfBirth.errorAboveMaxAge),
    maritalStatus: yup.string().required(content.personalDetails.maritalStatus.errorBlank),
    dependents: yup.number().required(content.personalDetails.dependents.errorBlank),
    'current-address-line-1': validateCurrentAddress(currentAddressErrors['current-address-line-1']),
    'current-address-line-2': validateCurrentAddress(currentAddressErrors['current-address-line-2']),
    'current-address-line-3': validateCurrentAddress(currentAddressErrors['current-address-line-3']),
    'current-postcode': yup.string().when('current-paf-match', (pafMatch) => {
      if (pafMatch === false) {
        return validatePostcode(currentAddressErrors['current-postcode']).test(
          'is not ciiom',
          currentAddressErrors['current-postcode'].ciiom,
          (postcode) => postcode !== undefined && !['JE', 'GY', 'IM'].includes(postcode.substring(0, 2).toUpperCase())
        );
      }
    }),
    'current-paf-match': yup.boolean().required(currentAddressErrors.blank),
    residentialStatus: yup.string().required(content.address.residentialStatus.errorBlank),
    currentDateMoved: yup.date().when(['dateOfBirth'], (dateOfBirth) =>
      yup
        .date()
        .transform((date, originalValue) => transformToValidDate(date, originalValue))
        .typeError(content.address.currentDateMoved.errors.invalid)
        .required(content.address.currentDateMoved.errors.blank)
        .min(minValidDate, content.address.currentDateMoved.errors.min)
        .max(maxValidDate, content.address.currentDateMoved.errors.max)
        .test(
          'before or same as date of birth',
          content.address.currentDateMoved.errors.dob,
          (value) => dateOfBirth === undefined || validateDateMoved(value, dateOfBirth)
        )
    ),
    'previous-address-line-1': validatePreviousAddress(
      previousUKAddressErrors['previous-address-line-1'],
      previousOverseas[0].errors
    ),
    'previous-address-line-2': validatePreviousAddress(
      previousUKAddressErrors['previous-address-line-2'],
      previousOverseas[1].errors
    ),
    'previous-address-line-3': validatePreviousAddress(
      previousUKAddressErrors['previous-address-line-3'],
      previousOverseas[2].errors,
      true
    ),
    'previous-address-line-4': validatePreviousAddress(undefined, previousOverseas[3].errors, true),
    'previous-address-line-5': validatePreviousAddress(undefined, previousOverseas[4].errors, true),
    'previous-postcode': yup
      .string()
      .when(
        ['currentDateMoved', 'previousCountry', 'previous-paf-match'],
        (currentDateMoved, previousCountry, pafMatch) => {
          if (isLessThanThreeYears(currentDateMoved) && previousCountry === 'GB' && pafMatch === false) {
            return validatePostcode(previousUKAddressErrors['previous-postcode']);
          }
        }
      ),
    'previous-paf-match': yup
      .boolean()
      .when(['currentDateMoved', 'previousCountry'], (currentDateMoved, previousCountry) => {
        if (isLessThanThreeYears(currentDateMoved) && previousCountry === 'GB') {
          return yup.boolean().required(previousUKAddressErrors.blank);
        }
      }),
    previousDateMoved: yup.mixed().when(['currentDateMoved', 'dateOfBirth'], (currentDateMoved, dateOfBirth) => {
      if (isLessThanThreeYears(currentDateMoved)) {
        return yup
          .date()
          .transform((date, originalValue) => transformToValidDate(date, originalValue))
          .typeError(content.address.previousDateMoved.errors.invalid)
          .required(content.address.previousDateMoved.errors.blank)
          .min(minValidDate, content.address.previousDateMoved.errors.min)
          .max(maxValidDate, content.address.previousDateMoved.errors.max)
          .test(
            'before or same as date of birth',
            content.address.previousDateMoved.errors.dob,
            (value) => dateOfBirth === undefined || validateDateMoved(value, dateOfBirth)
          )
          .test(
            'after current address',
            content.address.previousDateMoved.errors.current,
            (value) => currentDateMoved === undefined || value < currentDateMoved
          );
      }
    }),
    employmentStatus: yup.string().required(content.employment.employmentStatus.errorBlank),
    occupation: yup.string().when('employmentStatus', {
      is: (status) => ['FULL', 'PART', 'SELF'].includes(status),
      then: yup.string().required(content.employment.occupation.errorBlank),
    }),
    businessType: yup.string().when('employmentStatus', {
      is: (status) => ['FULL', 'PART', 'SELF'].includes(status),
      then: yup.string().required(content.employment.businessType.errorBlank),
    }),
    annualIncome: yup.number().required(content.income.annualIncome.errorBlank),
    payFrequency: yup.string().when(['employmentStatus', 'annualIncome'], (employmentStatus, annualIncome) => {
      if (['FULL', 'PART', 'SELF', 'PENSION', 'DISABILITY'].includes(employmentStatus) && annualIncome !== 0) {
        return yup.string().required(() => payFrequencyError(employmentStatus, content.income.payFrequency));
      }
    }),
  });
};

const financialDetailsSchema = (content) =>
  yup.object().shape({
    hasSavingsOrInvestments: yup.string().required(content.financialDetails.hasSavingsOrInvestments.errorBlank),
    hasHsbcProduct: yup.string().when('annualIncome', {
      is: (income) => income >= 75000,
      then: yup.string().required(content.financialDetails.hasHsbcProduct.errorBlank),
    }),
  });

const ucasSchema = (content) =>
  yup.object().shape({
    ucasCode: yup.string().when('employmentStatus', (employmentStatus) => {
      if (employmentStatus === 'EDUCATION') {
        return yup.string().required(content.employment.ucas.errorBlank);
      }
    }),
  });

const recentGraduateSchema = (content) =>
  yup.object().shape({
    // using .mixed() to defer the transform/date validation until we know employmentStatus/recentGraduate values meet the conditions
    graduationYear: yup.mixed().when(['employmentStatus', 'recentGraduate'], {
      is: (employmentStatus, recentGraduate) =>
        ['FULL', 'PART', 'UNEMPLOYED'].includes(employmentStatus) && recentGraduate === 'yes',
      then: yup
        .date()
        .transform((date, originalValue) => transformToValidDate(date, originalValue))
        .typeError(content.employment.graduationYear.errorBlank)
        .min(getYear(2), content.employment.graduationYear.errorMin)
        .max(getYear(0), content.employment.graduationYear.errorMax)
        .required(content.employment.graduationYear.errorBlank),
    }),
  });

const appendSchemas = (schema, content) => {
  if (content.financialDetails) {
    schema = financialDetailsSchema(content).concat(schema);
  }
  if (content.employment.ucas) {
    schema = ucasSchema(content).concat(schema);
  }
  if (content.employment.recentGraduate) {
    schema = recentGraduateSchema(content).concat(schema);
  }
  return schema;
};

export const createCustomerDetailsSchema = (content) => appendSchemas(createCommonFieldsSchema(content), content);

export const createAdjustedOverdraftSchema = (content) =>
  yup.object().shape({
    adjustedOverdraftLimit: yup
      .number()
      .max(content.overdraft.maxAmount, content.overdraft.errorMax)
      .required(content.overdraft.errorBlank)
      .test('isAMultipleOf50', content.overdraft.errorInvalid, (value) => value % 50 === 0),
  });
