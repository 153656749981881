import React, { Component } from 'react';
import {
  IconStatusInformationOnlight,
  Heading,
  HorizontalRule,
  Paragraph,
  Button,
  FormLayout,
  Section,
  Link,
} from '@piggybank/core';
import BrandContext from '../../shared/brandContext';
import { renderRepeatedComponent } from '../../shared/utils';
import { getTealiumPageDefaults } from '../../shared/tealium';
import { tealiumView } from '../../shared/utils';

class DeclinePage extends Component {
  componentDidMount() {
    document.title = this.context.brand.content.declinePage.browserTitle;
    tealiumView({
      ...getTealiumPageDefaults(['decline']),
      ...{
        event_category: 'tool',
        event_action: 'declined',
        funnel_step: '6',
        funnel_step_name: 'decline',
        tool_name: '',
      },
    });
  }

  render() {
    const content = this.context.brand.content.declinePage;
    const iconColour = this.context.brand.settings.iconColours.decline;
    return (
      <FormLayout marginBottom={6}>
        <div
          style={{
            margin: '3px 0px 3px -2px',
            padding: '3px',
            color: iconColour,
          }}
        >
          <IconStatusInformationOnlight width='36' data-bdd='error-icon' />
        </div>
        <Heading level={1}>{content.pageTitle}</Heading>
        <HorizontalRule marginBottom={6} />
        <Section>{renderRepeatedComponent(content.copy, Paragraph, 4)}</Section>
        {content.section && (
          <Section>
            <Heading level={3}>{content.section.heading}</Heading>
            <Paragraph>
              {content.section.copy}
              <Link href={content.section.creditScoringLink} external>
                {content.section.creditScoringCopy}
              </Link>
            </Paragraph>
          </Section>
        )}
        <Button elementType='a' href={content.url}>
          {content.button}
        </Button>
      </FormLayout>
    );
  }
}

DeclinePage.contextType = BrandContext;

export default DeclinePage;
