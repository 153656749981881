import React from 'react';

export default {
  browserTitle: 'Overdraft Eligibility - Welcome',
  pageTitle: 'Overdraft eligibility checker',
  pageSubTitle: 'Find out about the likelihood of being able to get a certain overdraft',
  expander: {
    title: 'What is an eligibility check?',
    copy: [
      'The overdraft eligibility checker will give you an indication of whether or not you’ll be accepted for an overdraft of a certain amount.',
      'The checker works by running a ‘quotation’ search on your credit file.  You’ll be able to see the search on your file, but it won’t be seen by any other organisations, so checking won’t affect your credit rating.',
      <>
        The results of the overdraft eligibility checker are to give you an idea of an overdraft amount we could give
        you. They don’t constitute an offer from <b>first direct</b> to give you credit. If you decide to apply for the
        overdraft amount you’ve checked, that’s when we'll carry out the full credit reference agency and affordability
        check. We reserve the right to decline any application for credit facilities or to offer to lend on different
        terms.
      </>,
      'The results given by the checker are based on the accuracy of your answers to the questions in the form.',
    ],
  },
  sectionTitle: 'Please check the following',
  criteria: ['you’re 18 or over and a permanent UK resident', "you don't require a joint account"],
  cta: 'Confirm and start',
};
