import React, { Component } from 'react';
import { Heading, IconStatusInformationOnlight, FormLayout, Paragraph, Button, Section } from '@piggybank/core';
import BrandContext from '../../shared/brandContext';
import { renderRepeatedComponent } from '../../shared/utils';
import { getTealiumPageDefaults } from '../../shared/tealium';
import { tealiumView } from '../../shared/utils';

class TimedOut extends Component {
  componentDidMount() {
    document.title = this.context.brand.content.timeout.timedOut.browserTitle;

    tealiumView({
      ...getTealiumPageDefaults(['error', 'timeout']),
      ...{
        event_category: 'error',
        event_action: 'application',
        event_content: 'application timeout',
        page_type: 'error',
        product_category: '',
        funnel_step_name: 'timeout popup',
        error_type: 'application',
        tool_name: '',
      },
    });

    this.heading.focus();
  }

  render() {
    const content = this.context.brand.content.timeout.timedOut;
    const iconColour = this.context.brand.settings.iconColours.timeout;
    return (
      <FormLayout marginBottom={6}>
        <div
          style={{
            margin: '3px 0px 3px -2px',
            padding: '3px',
            color: iconColour,
          }}
        >
          <IconStatusInformationOnlight width='36' />
        </div>

        <Heading level={1} marginBottom={4} ref={(heading) => (this.heading = heading)}>
          {content.heading}
        </Heading>
        <Section>{renderRepeatedComponent(content.copy, Paragraph, 4)}</Section>

        <Button
          type='button'
          onClick={(e) => {
            e.stopPropagation();
            window.location = '/';
          }}
        >
          {content.buttonText}
        </Button>
      </FormLayout>
    );
  }
}

TimedOut.contextType = BrandContext;
export default TimedOut;
