import React, { Component } from 'react';
import Countdown from './Countdown';
import TimedOut from './TimedOut';
import BrandContext from '../../shared/brandContext';

class Timeout extends Component {
  static events = ['load', 'mousedown', 'click', 'scroll', 'keydown'];

  constructor(props) {
    super(props);

    this.state = { mode: 'active' };
    this.setTimeout = this.setTimeout.bind(this);
    this.resetTimeouts = this.resetTimeouts.bind(this);
    this.onActivity = this.onActivity.bind(this);
    this.onWarningTimeout = this.onWarningTimeout.bind(this);
    this.clearWarning = this.clearWarning.bind(this);
  }

  componentDidMount() {
    Timeout.events.forEach((event) => {
      window.addEventListener(event, this.onActivity);
    });

    this.countdown = this.context.brand.settings.timeout.timeout - this.context.brand.settings.timeout.warning;
    this.setTimeout();
  }

  componentWillUnmount() {
    Timeout.events.forEach((event) => {
      window.removeEventListener(event, this.onActivity);
    });
  }

  onWarningTimeout() {
    this.setState({ mode: 'warning', countdown: this.countdown });

    this.countdownInterval = setInterval(() => {
      if (this.state.countdown <= 0) {
        this.setState({ mode: 'timedOut' });
        clearInterval(this.countdownInterval);
      } else {
        this.setState((state) => ({ countdown: state.countdown - 1000 }));
      }
    }, 1000);
  }

  setTimeout() {
    this.warningTimeout = setTimeout(this.onWarningTimeout, this.context.brand.settings.timeout.warning);
  }

  onActivity() {
    if (this.state.mode === 'active') {
      this.resetTimeouts();
    }
  }

  resetTimeouts() {
    if (this.state.mode === 'active') {
      clearTimeout(this.warningTimeout);
    }

    if (this.state.mode === 'warning') {
      clearInterval(this.countdownInterval);
    }

    this.setState({ mode: 'active' });
    this.setTimeout();
  }

  clearWarning() {
    this.resetTimeouts();
  }

  render() {
    const { mode } = this.state;

    if (mode === 'timedOut') {
      return <TimedOut />;
    }

    return (
      <>
        {this.props.children}
        {mode === 'warning' && <Countdown time={this.state.countdown} clearWarning={this.clearWarning} />}
      </>
    );
  }
}

Timeout.contextType = BrandContext;

export default Timeout;
