import React from 'react';
import { Heading, Callout, Paragraph } from '@piggybank/core';
import { Field, FieldFeedback, RangeInput, CurrencyInput, Hint, Label } from '@piggybank/form';
import BrandContext from '../../shared/brandContext';
import './OverdraftDetails.css';

const OverdraftDetails = () => (
  <BrandContext.Consumer>
    {({ brand }) => {
      const pageContent = brand.content.customerDetails.overdraft;
      const maxAmount = pageContent.maxAmount;
      return (
        <>
          <Heading level={2} accentBar>
            {pageContent.heading}
          </Heading>
          <Field name='overdraft'>
            <Label id='overdraft-field-label' marginBottom={1}>
              {pageContent.label}
            </Label>
            <Hint>{pageContent.hint}</Hint>
            <CurrencyInput maxLength={4} symbol='£' aria-labelledby='overdraft-field-label' aria-live='assertive' />
            <FieldFeedback />
            <RangeInput
              min='0'
              max={maxAmount.toString()}
              step='50'
              tickLocations={['0', pageContent.maxAmount]}
              trackLabels={pageContent.trackLabels}
              marginBottom={pageContent.copy ? 2 : 0}
              aria-labelledby='overdraft-field-label'
            />
            {pageContent.copy && (
              <Callout type='key-information'>
                <Paragraph marginBottom={0}>{pageContent.copy}</Paragraph>
              </Callout>
            )}
          </Field>
        </>
      );
    }}
  </BrandContext.Consumer>
);

export default OverdraftDetails;
