import React, { Component } from 'react';
import {
  Image,
  Heading,
  Paragraph,
  Link,
  Button,
  HorizontalRule,
  IconStatusInformationOnlight,
  IconStatusYesThick,
  ModalDialog,
  Section,
  IconNewModal,
  ListItem,
  UnorderedList,
  VisuallyHidden,
} from '@piggybank/core';
import './Account.css';
import {
  renderUnorderedList,
  renderRepeatedComponent,
  evaluateLikelihood,
  retrieveAccountDetails,
  isUnlikely,
  renderLikelihoodCopy,
  forceFocusToLink,
  renderLikelihoodDefinitions,
} from '../../shared/utils';
import BrandContext from '../../shared/brandContext';
import { getTealiumPageDefaults } from '../../shared/tealium';
import { tealiumEvent } from '../../shared/utils';

const renderPremierCriteriaList = (criteriaList) => {
  return (
    <UnorderedList>
      {criteriaList.map((item, i) => {
        return (
          <div key={i}>
            {i !== 0 && <Paragraph>OR</Paragraph>}
            <ListItem>{criteriaList[i]}</ListItem>
          </div>
        );
      })}
    </UnorderedList>
  );
};

const renderStudentCriteriaList = (accountInformation) => {
  return (
    <UnorderedList>
      <ListItem>{accountInformation.criteriaList[0]}</ListItem>
      <ListItem>
        {accountInformation.criteriaList[1]}
        {accountInformation.criteriaSubList && renderUnorderedList(accountInformation.criteriaSubList)}
      </ListItem>
    </UnorderedList>
  );
};

class Account extends Component {
  render() {
    const pageContent = this.context.brand.content.account;
    const accountInformation = retrieveAccountDetails(
      this.context.brand.content.accountInformation,
      this.props.response
    );
    const iconColours = this.context.brand.settings.iconColours;
    const likelihood = evaluateLikelihood(this.props.values.adjustedOverdraftLimit, this.props.response, pageContent);
    return (
      <Section marginBottom={5} id='accountInfo'>
        <Heading level={2}>{accountInformation.displayName}</Heading>
        <div className='accountInfoBox'>
          <div className='cardImage'>
            <Image src={accountInformation.cardImage} alt='' marginBottom={0} width={120} />
          </div>
          <div className='likelihoodResultContent'>
            <div>
              <span className='likehoodContentIcon'>
                {isUnlikely(likelihood, pageContent) ? (
                  <IconStatusInformationOnlight inline style={{ color: iconColours.likelihood }} />
                ) : (
                  <IconStatusYesThick inline style={{ color: '#00847F' }} />
                )}
                <Paragraph id='likelihoodResult'>
                  <span style={{ fontWeight: 'bold', display: 'contents' }}>{likelihood}</span> 
                  {renderLikelihoodCopy(likelihood, this.props.response, this.props.values, pageContent)}
                </Paragraph>
              </span>
            </div>
            <div className='likelihoodModal'>
              <ModalDialog
                data-bdd='likelihoodModal'
                alert
                title={pageContent.likelihoodModal.title}
                getApplicationNode={() => document.getElementById('root')}
                onOpen={() => {
                  tealiumEvent('link', {
                    ...getTealiumPageDefaults(['results']),
                    ...{
                      event_action: 'onsite',
                      event_content: `what does ${likelihood.toLowerCase()} mean: ${accountInformation.accountType}`,
                      funnel_step_name: 'results',
                    },
                  });
                }}
                onClose={() => forceFocusToLink(this.likelihoodLink)}
                renderOpenButton={({ openDialog }) => (
                  <Paragraph marginBottom={0}>
                    {pageContent.likelihoodModal.preLink}
                    {' '}
                    <Link
                      data-bdd='likelihoodModalLink'
                      href='#'
                      ref={(likelihoodLink) => (this.likelihoodLink = likelihoodLink)}
                      onClick={openDialog}
                      style={{ fontWeight: 'bold' }}
                      iconSlotRight={<IconNewModal inline />}
                    >
                      {likelihood.toLowerCase()} {pageContent.likelihoodModal.postLink}
                      <VisuallyHidden>Opens a modal dialog</VisuallyHidden>
                    </Link>
                  </Paragraph>
                )}
              >
                {({ closeDialog }) => (
                  <>
                    {renderRepeatedComponent(pageContent.likelihoodModal.copy, Paragraph)}
                    {renderUnorderedList(pageContent.likelihoodModal.definitions.map(renderLikelihoodDefinitions), {
                      spacing: 2,
                    })}
                  </>
                )}
              </ModalDialog>
            </div>
          </div>
        </div>

        <HorizontalRule />

        <Heading level={3} marginBottom={3}>
          {pageContent.accountBenefitsHeading}
        </Heading>
        {renderUnorderedList(accountInformation.features, { spacing: 3 })}
        {accountInformation.accountType === 'basic' && (
          <Paragraph style={{ fontWeight: 'bold' }}>{pageContent.basicAccountCopy}</Paragraph>
        )}
        <Paragraph small>{pageContent.accountBenefitsCopy}</Paragraph>

        <ModalDialog
          data-bdd='accountCriteriaModal'
          alert
          title={`${pageContent.accountCriteriaModal.preLink} ${accountInformation.displayName} ${pageContent.accountCriteriaModal.postLink}`}
          onOpen={() => {
            tealiumEvent('link', {
              ...getTealiumPageDefaults(['results']),
              ...{
                event_action: 'onsite',
                event_content: 'account criteria: ' + accountInformation.accountType,
                funnel_step_name: 'results',
              },
            });
          }}
          onClose={() => forceFocusToLink(this.accountCriteriaLink)}
          renderOpenButton={({ openDialog }) => (
            <Paragraph marginBottom={5}>
              <Link
                data-bdd='accountCriteriaModalLink'
                ref={(accountCriteriaLink) => (this.accountCriteriaLink = accountCriteriaLink)}
                href='#'
                onClick={openDialog}
                iconSlotRight={<IconNewModal inline />}
              >
                {pageContent.accountCriteriaModal.link}
                <VisuallyHidden>Opens a modal dialog</VisuallyHidden>
              </Link>
            </Paragraph>
          )}
        >
          {({ closeDialog }) => (
            <>
              {renderRepeatedComponent(accountInformation.criteria, Paragraph)}
              {accountInformation.accountType === 'premier' &&
                accountInformation.criteriaList &&
                renderPremierCriteriaList(accountInformation.criteriaList)}
              {accountInformation.accountType === 'student' &&
                accountInformation.criteriaList &&
                renderStudentCriteriaList(accountInformation)}
            </>
          )}
        </ModalDialog>

        <Button
          elementType='a'
          external
          href={accountInformation.url}
          style={{ width: '100%' }}
          onClick={() => {
            tealiumEvent('link', {
              ...getTealiumPageDefaults(['results']),
              ...{
                event_action: 'button',
                event_content: 'learn more and apply: ' + accountInformation.accountType,
                funnel_step_name: 'results',
                account_type: accountInformation.accountType,
                tool_event: 'completed',
              },
            });
          }}
        >
          {pageContent.applyButton}
          <VisuallyHidden>{accountInformation.displayName}</VisuallyHidden>
        </Button>
      </Section>
    );
  }
}

Account.contextType = BrandContext;
export default Account;
