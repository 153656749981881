import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { DefaultBrandProvider } from './components/BrandProvider';

ReactDOM.render(
  <BrowserRouter>
    <DefaultBrandProvider>
      <App />
    </DefaultBrandProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

if (process.env.NODE_ENV !== 'production') {
  const { detect } = require('detect-browser');
  const browser = detect();
  if (browser && browser.name === 'chrome') {
    var axe = require('react-axe');
    axe(React, ReactDOM, 1000);
  }
}
