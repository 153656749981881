import React, { Component } from 'react';
import { Loading, Heading, Paragraph } from '@piggybank/core';
import BrandContext from '../../shared/brandContext';
import { getTealiumPageDefaults } from '../../shared/tealium';
import './LoadingPage.css';
import { tealiumView } from '../../shared/utils';

class LoadingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  componentDidUpdate() {
    const findHeading = document.getElementById('heading');
    if (this.props.loading) {
      findHeading.focus();
    }
  }

  render() {
    if (this.props.loading) {
      tealiumView({
        ...getTealiumPageDefaults(['processing']),
        ...{
          event_category: '',
          funnel_step: '5',
          funnel_step_name: 'processing',
          tool_name: '',
        },
      });
    }

    const pageContent = this.context.brand.content.loadingPage;

    return (
      <Loading
        show={this.props.loading}
        getApplicationNode={() => document.getElementById('root')}
        logoSlot={pageContent.logo()}
      >
        <Heading id='heading' aria-live='assertive' level={3} marginBottom={4} tabIndex='0'>
          {pageContent.heading}
        </Heading>
        <Paragraph
          aria-label={process.env.BRAND === 'fsdt' ? pageContent.ariaLabel : undefined}
          aria-live='polite'
          tabIndex='0'
        >
          {pageContent.copy}
        </Paragraph>
      </Loading>
    );
  }
}
LoadingPage.contextType = BrandContext;
export default LoadingPage;
