import React, { Component } from 'react';
import { PlaybackValue, IconEdit, Paragraph, Link, IconStatusNoThick, Button } from '@piggybank/core';
import { CurrencyInput, Legend, Fieldset, FieldFeedback, Hint, Label } from '@piggybank/form';
import { currency } from '../../shared/utils';
import BrandContext from '../../shared/brandContext';
import './EditOverdraft.css';
import { getTealiumPageDefaults } from '../../shared/tealium';
import { tealiumEvent } from '../../shared/utils';
class EditOverdraft extends Component {
  constructor(props) {
    super(props);

    this.state = {
      overdraft: undefined,
      edit: false,
      focusOverdraftAmount: false,
      focusEditButton: false,
    };
  }

  componentDidUpdate() {
    if (this.state.focusOverdraftAmount) {
      this.overdraftAmount.focus();
      this.setState({ focusOverdraftAmount: false });
    }

    if (this.state.focusEditButton) {
      this.editButton.focus();
      this.setState({ focusEditButton: false });
    }
  }

  render() {
    const applicationComplete = this.context.brand.content.applicationComplete;
    const values = this.props.values;
    return (
      <>
        {!this.state.edit && (
          <>
            <div className='editOverdraftEditPanel'>
              <Paragraph marginBottom={2}>{applicationComplete.overdraft.label}</Paragraph>
              <PlaybackValue marginBottom={2}>{currency(values.adjustedOverdraftLimit)}</PlaybackValue>
              <div className='editOverdraftEditButton'>
                <Link
                  data-bdd='editButton'
                  elementType='button'
                  type='button'
                  hoverUnderline
                  onClick={() => {
                    this.setState({
                      overdraft: values.adjustedOverdraftLimit,
                      edit: true,
                      focusOverdraftAmount: true,
                    });
                    tealiumEvent('link', {
                      ...getTealiumPageDefaults(['results']),
                      ...{
                        event_action: 'button',
                        event_content: 'edit',
                        funnel_step_name: 'results',
                      },
                    });
                  }}
                  iconSlotLeft={<IconEdit inline />}
                  ref={(editButton) => (this.editButton = editButton)}
                >
                  {applicationComplete.overdraft.editButton}
                </Link>
              </div>
            </div>
          </>
        )}
        {this.state.edit && (
          <>
            <Link
              data-bdd='cancelButton'
              elementType='button'
              type='button'
              hoverUnderline
              onClick={() => {
                this.props.setFieldValue('adjustedOverdraftLimit', this.state.overdraft);
                this.setState({
                  edit: false,
                  overdraft: undefined,
                  focusEditButton: true,
                });
              }}
              iconSlotLeft={<IconStatusNoThick inline />}
              style={{ float: 'right' }}
            >
              {applicationComplete.overdraft.cancelButton}
            </Link>

            <Fieldset name='adjustedOverdraftLimit' marginBottom={2}>
              <Legend marginBottom={2}>{applicationComplete.overdraft.label}</Legend>
              <Hint>{applicationComplete.overdraft.hint}</Hint>
              <div style={{ display: 'flex' }}>
                <Label marginBottom={0}>
                  <CurrencyInput
                    style={{ width: '180px' }}
                    symbol='£'
                    maxLength={4}
                    ref={(overdraftAmount) => (this.overdraftAmount = overdraftAmount)}
                  />
                </Label>
                <Button
                  data-bdd='updateButton'
                  type='submit'
                  style={{ marginLeft: '24px' }}
                  onClick={() => {
                    if (this.props.errors.adjustedOverdraftLimit === undefined) {
                      this.setState({
                        edit: false,
                        overdraft: undefined,
                        focusEditButton: true,
                      });
                    }
                  }}
                >
                  {applicationComplete.overdraft.updateButton}
                </Button>
              </div>
              <FieldFeedback marginBottom={2} />
            </Fieldset>
          </>
        )}
      </>
    );
  }
}

EditOverdraft.contextType = BrandContext;
export default EditOverdraft;
