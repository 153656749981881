import React, { Component } from 'react';
import { ModalDialog, Paragraph, Button, ButtonRow, VisuallyHidden } from '@piggybank/core';
import BrandContext from '../../shared/brandContext';
import { tealiumDefaults } from '../../shared/tealium';
import { renderRepeatedComponent, tealiumEvent } from '../../shared/utils';

class Countdown extends Component {
  componentDidMount() {
    tealiumEvent('link', {
      ...tealiumDefaults,
      ...{
        page_url: window.utag_data.page_url,
        page_name: window.utag_data.page_name,
        event_action: 'popup',
        event_content: 'need more time',
        funnel_step_name: 'timeout popup',
        page_subcategory: '',
        tool_name: '',
      },
    });
  }

  render() {
    const content = this.context.brand.content.timeout.countdown;
    const seconds = Math.floor(this.props.time / 1000);
    const remainingSeconds = seconds < 10 ? `0${seconds}` : seconds;
    const maxTimeRemaining =
      (this.context.brand.settings.timeout.timeout - this.context.brand.settings.timeout.warning) / 1000;

    return (
      <ModalDialog
        title={content.title}
        show
        onClose={this.props.clearWarning}
        aria-live={seconds === maxTimeRemaining ? 'polite' : 'off'}
      >
        {renderRepeatedComponent(content.copy, Paragraph)}
        <Paragraph marginBottom={6}>
          {content.timeRemainingBefore} {seconds < 1 ? '00' : remainingSeconds} {content.timeRemainingAfter}
        </Paragraph>
        <ButtonRow>
          <Button
            type='button'
            onClick={() => {
              tealiumEvent('link', {
                ...tealiumDefaults,
                ...{
                  page_url: window.utag_data.page_url,
                  page_name: window.utag_data.page_name,
                  event_action: 'button',
                  event_content: 'continue application',
                  funnel_step_name: 'timeout popup',
                  page_subcategory: '',
                  tool_name: '',
                },
              });
              this.props.clearWarning();
            }}
          >
            {content.buttonText}
            <VisuallyHidden>button</VisuallyHidden>
          </Button>
        </ButtonRow>
      </ModalDialog>
    );
  }
}

Countdown.contextType = BrandContext;

export default Countdown;
