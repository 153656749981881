import React from 'react';
import { DateTime, Interval } from 'luxon';
import { DateInput, Field, Fieldset, FieldFeedback, Label, Legend, Radio, Select, Hint } from '@piggybank/form';
import { Heading, Section, Row, Cell } from '@piggybank/core';
import BrandContext from '../../shared/brandContext';
import PreviousOverseas from '../PreviousOverseas';
import { PcaAddress, loqateSearch, loqateSelect } from '@originations/pattern-library';
import { getTealiumPageDefaults } from '../../shared/tealium';
import './AddressDetails.css';
import { tealiumEvent } from '../../shared/utils';

const today = new Date();
const AddressDetails = ({ values, setFieldValue }) => (
  <BrandContext.Consumer>
    {({ brand }) => {
      const pageContent = brand.content.customerDetails.address;
      const lookups = brand.lookups;
      const apiKey = process.env.REACT_APP_PCA_API_KEY;
      const funnelStep = 'personal details';

      const showPrevious = () => Interval.fromDateTimes(new Date(values.currentDateMoved), today).length('years') < 3;

      const formatDate = () => DateTime.fromISO(values.currentDateMoved + '-01').toFormat('MMMM yyyy');

      return (
        <>
          <Heading level={2} accentBar={pageContent.heading.accentBar}>
            {pageContent.heading.copy}
          </Heading>
          <Section marginBottom={5}>
            <PcaAddress
              apiKey={apiKey}
              content={pageContent.current}
              searchAddress={loqateSearch}
              selectAddress={loqateSelect}
              setFieldValue={setFieldValue}
              space={'current'}
              onClickManualAddress={() => {
                tealiumEvent('link', {
                  ...getTealiumPageDefaults([funnelStep]),
                  ...{
                    event_action: 'button',
                    event_content: 'enter current address manually',
                    funnel_step_name: funnelStep,
                  },
                });
              }}
              onClickEdit={() => {
                tealiumEvent('link', {
                  ...getTealiumPageDefaults([funnelStep]),
                  ...{
                    event_action: 'button',
                    event_content: 'edit your current address',
                    funnel_step_name: funnelStep,
                  },
                });
              }}
              streetNameIsRequired={true}
            />
            <Field name='current-paf-match'>
              <FieldFeedback />
            </Field>
          </Section>

          <Fieldset name='residentialStatus' marginBottom={4}>
            <Legend marginBottom={2}>{pageContent.residentialStatus.label}</Legend>
            {lookups.residentialStatus.map((option, index) => (
              <Radio data-bdd={`residential-status-option-${index}`} value={option.value} key={option.value}>
                {option.label}
              </Radio>
            ))}
            <FieldFeedback marginTop={1} />
          </Fieldset>
          <Fieldset name='currentDateMoved' marginBottom={showPrevious() ? 5 : 0}>
            <Legend marginBottom={1}>{pageContent.currentDateMoved.label}</Legend>
            <Hint marginBottom={1}>{pageContent.currentDateMoved.hint}</Hint>
            <DateInput format='YYYY-MM' />
            <FieldFeedback />
          </Fieldset>
          {showPrevious() && (
            <>
              <Heading level={3} tabIndex='0'>
                {pageContent.previousAddress} {formatDate()}
                {'?'}
              </Heading>
              <Field name='previousCountry' marginBottom={values.previousCountry ? 5 : 0}>
                <Label>{pageContent.previousCountry}</Label>
                <Row>
                  <Cell sizeMedium={7} sizeLarge={5}>
                    <Select
                      fullWidth
                      options={lookups.countries}
                      placeholder={pageContent.previousCountry.placeholder}
                    />
                  </Cell>
                </Row>
                <FieldFeedback />
              </Field>
              {values.previousCountry === 'GB' && (
                <Section marginBottom={5}>
                  <PcaAddress
                    apiKey={apiKey}
                    content={pageContent.previousUk}
                    searchAddress={loqateSearch}
                    selectAddress={loqateSelect}
                    setFieldValue={setFieldValue}
                    space={'previous'}
                    onClickManualAddress={() => {
                      tealiumEvent('link', {
                        ...getTealiumPageDefaults([funnelStep]),
                        ...{
                          event_action: 'button',
                          event_content: 'enter previous address manually',
                          funnel_step_name: funnelStep,
                        },
                      });
                    }}
                    onClickEdit={() => {
                      tealiumEvent('link', {
                        ...getTealiumPageDefaults([funnelStep]),
                        ...{
                          event_action: 'button',
                          event_content: 'edit your previous address',
                          funnel_step_name: funnelStep,
                        },
                      });
                    }}
                    streetNameIsRequired={true}
                  />
                  <Field name='previous-paf-match'>
                    <FieldFeedback />
                  </Field>
                </Section>
              )}
              {values.previousCountry && values.previousCountry !== 'GB' && (
                <Section marginBottom={5}>
                  <PreviousOverseas />
                </Section>
              )}
              {values.previousCountry && (
                <Fieldset name='previousDateMoved' marginBottom={0}>
                  <Legend marginBottom={1}>{pageContent.previousDateMoved.label}</Legend>
                  <DateInput format='YYYY-MM' />
                  <FieldFeedback />
                </Fieldset>
              )}
            </>
          )}
        </>
      );
    }}
  </BrandContext.Consumer>
);

export default AddressDetails;
