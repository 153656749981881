import React from 'react';
import {
  Fieldset,
  Field,
  Label,
  Select,
  FieldFeedback,
  Legend,
  YesNoRadio,
  DateInput,
  Checkbox,
  FilterableSelect,
} from '@piggybank/form';
import { Heading, Section, IconSearch } from '@piggybank/core';
import { isEmployedOrUnemployed, isEmployedOrSelfEmployed } from '../../shared/utils';
import BrandContext from '../../shared/brandContext';
import './EmploymentDetails.css';
import cn from 'classnames';

const customFilter = (items, input) =>
  items.filter(
    (item) =>
      item.label.toLowerCase().includes(input.toLowerCase()) || item.value.toLowerCase().includes(input.toLowerCase())
  );

const highlightText = (text, highlight) => {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={index} className='dropDownListItemHighlight'>
            {part}
          </span>
        ) : (
          part
        )
      )}
    </>
  );
};

const EmploymentDetails = ({ values, setFieldValue }) => (
  <BrandContext.Consumer>
    {({ brand }) => {
      const pageContent = brand.content.customerDetails.employment;
      const lookups = brand.lookups;
      let unknownUcasCode;
      let ucasCodeUnknown;

      if (pageContent.ucas) {
        unknownUcasCode = {
          value: brand.settings.categoryCodes.unknownUcasCode,
          label: pageContent.ucas.unknown,
        };

        ucasCodeUnknown = values.ucasCode === unknownUcasCode.value;
      }

      return (
        <>
          <Heading level={2} accentBar>
            {pageContent.heading}
          </Heading>
          <Field name='employmentStatus'>
            <Label>{pageContent.employmentStatus.label}</Label>
            <Select options={lookups.employmentStatus} />
            <FieldFeedback />
          </Field>

          {isEmployedOrUnemployed(values.employmentStatus) && pageContent.recentGraduate && (
            <Section marginBottom={5}>
              <Fieldset name='recentGraduate'>
                <Legend>{pageContent.recentGraduate.label}</Legend>
                <YesNoRadio />
                <FieldFeedback />
              </Fieldset>

              {values.recentGraduate === 'yes' && (
                <Field name='graduationYear'>
                  <Label>{pageContent.graduationYear.label}</Label>
                  <DateInput format='YYYY' />
                  <FieldFeedback />
                </Field>
              )}
            </Section>
          )}

          {isEmployedOrSelfEmployed(values.employmentStatus) && (
            <Section>
              <Field name='occupation'>
                <Label>{pageContent.occupation.label}</Label>
                <Select options={lookups.occupation} />
                <FieldFeedback />
              </Field>

              <Field name='businessType'>
                <Label>{pageContent.businessType.label}</Label>
                <Select options={lookups.businessType} />
                <FieldFeedback />
              </Field>
            </Section>
          )}

          {values.employmentStatus === 'EDUCATION' && pageContent.ucas && (
            <Section>
              <Field name='ucasCode'>
                <Label tabIndex='0'>{pageContent.ucas.question}</Label>
                <FilterableSelect
                  role='list'
                  hideInlineAutocomplete
                  hideToggleButton
                  items={[...lookups.ucasCodes, unknownUcasCode]}
                  disabled={ucasCodeUnknown}
                  filterItems={customFilter}
                  renderBefore={({ textInputFocused, selectedItem, isOpen }) => {
                    return (
                      !textInputFocused &&
                      !selectedItem &&
                      !isOpen && (
                        <span className='searchIconWrapper'>
                          <IconSearch />
                        </span>
                      )
                    );
                  }}
                  renderSuggestion={({
                    inputValue,
                    suggestion: { label, paragraph },
                    isHighlighted,
                    isPressed,
                    isSelected,
                  }) => {
                    return (
                      <div
                        className={cn('dropDownListItem', {
                          highlightedItem: isHighlighted,
                          activeItem: isPressed,
                          selectedItem: isSelected,
                        })}
                      >
                        <span>{highlightText(label, inputValue)}</span>
                      </div>
                    );
                  }}
                />
                <FieldFeedback />
              </Field>
              <Field name='cantFindUcasCode'>
                <Checkbox
                  checked={ucasCodeUnknown}
                  onClick={() =>
                    ucasCodeUnknown ? setFieldValue('ucasCode', '') : setFieldValue('ucasCode', unknownUcasCode.value)
                  }
                >
                  {pageContent.ucas.checkbox}
                </Checkbox>
              </Field>
            </Section>
          )}
        </>
      );
    }}
  </BrandContext.Consumer>
);

export default EmploymentDetails;
