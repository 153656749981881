import React from 'react';
import { Field, FieldFeedback, Label, TextInput } from '@piggybank/form';
import { Row, Cell } from '@piggybank/core';
import BrandContext from '../../shared/brandContext';

const PreviousOverseas = () => (
  <BrandContext.Consumer>
    {({ brand }) => {
      const pageContent = brand.content.customerDetails.address.previousOverseas;
      return (
        <>
          {pageContent.map((copy, i) => (
            <Field name={`previous-address-line-${i + 1}`} key={i}>
              <Label marginBottom={1} data-bdd={`previous-address-line-${i + 1}-label`}>
                {copy.label}
              </Label>
              <Row>
                <Cell sizeMedium={7} sizeLarge={5}>
                  <TextInput fullWidth maxLength={33} data-bdd={`previous-address-line-${i + 1}-input`} />
                </Cell>
              </Row>
              <FieldFeedback data-bdd={`previous-address-line-${i + 1}-feedback`} />
            </Field>
          ))}
        </>
      );
    }}
  </BrandContext.Consumer>
);

export default PreviousOverseas;
