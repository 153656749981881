import React, { Component } from 'react';
import { Heading, FormLayout, HorizontalRule, Section, Paragraph } from '@piggybank/core';
import { Form } from '@piggybank/form';
import { createAdjustedOverdraftSchema } from '../../shared/validation-schema';
import BrandContext from '../../shared/brandContext';
import EditOverdraft from '../../components/EditOverdraft/EditOverdraft';
import { getTealiumPageDefaults } from '../../shared/tealium';
import { tealiumView } from '../../shared/utils';

const renderAccounts = (values, response, Component) =>
  response.offeredProducts.map((account, i) =>
    account.systemDecision !== 'R1' ? <Component key={i} values={values} response={account} /> : null
  );

class ApplicationComplete extends Component {
  componentDidMount() {
    document.title = this.context.brand.content.applicationComplete.browserTitle;
    tealiumView({
      ...getTealiumPageDefaults(['results']),
      ...{
        event_category: '',
        funnel_step: '6',
        funnel_step_name: 'results',
      },
    });
  }

  render() {
    const applicationComplete = this.context.brand.content.applicationComplete;
    const accountType = this.context.brand.settings.applicationComplete.accountType;
    return (
      <FormLayout>
        <Heading level={1}>{applicationComplete.pageTitle}</Heading>
        <Paragraph lead>{applicationComplete.pageIntro}</Paragraph>
        <HorizontalRule marginBottom={6} />
        <Form
          initialValues={{ adjustedOverdraftLimit: this.props.overdraft }}
          validationSchema={createAdjustedOverdraftSchema(applicationComplete)}
        >
          {({ values, errors, setFieldValue }) => (
            <Section>
              <Section marginBottom={5}>
                <EditOverdraft values={values} setFieldValue={setFieldValue} errors={errors} />
              </Section>
              {renderAccounts(values, this.props.data, accountType)}
            </Section>
          )}
        </Form>
      </FormLayout>
    );
  }
}

ApplicationComplete.contextType = BrandContext;

export default ApplicationComplete;
