export default {
  definitions: {
    mostLikely: 'High possibility',
    likely: 'Probable',
    unlikely: 'Low possibility',
    leastLikely: 'Very low possibility',
  },
  likelyCopy: 'to be eligible for',
  unlikelyCopy: 'reduce amount to less than',
  extremelyUnlikelyCopy: 'an arranged overdraft limit',
  copy: 'Subject to further eligibility checks.',
  likelihoodModal: {
    preLink: 'What does',
    postLink: 'mean?',
    title: 'What do these results mean?',
    copy: [
      'The result gives an indication of how likely your request for a certain arranged overdraft limit is to be accepted if you choose to apply for it.',
      'There are four possible outcomes that are driven by the information you put into the checker form, these are:',
    ],
    definitions: [
      {
        definition: 'high possibility ',
        copy: '- it’s very likely your request for the arranged overdraft limit you asked for would be accepted',
      },
      {
        definition: 'probable',
        copy: '- it’s likely you’d be accepted for the arranged overdraft limit you asked for',
      },
      {
        definition: 'low possibility',
        copy: '- your requested arranged overdraft limit probably won’t be accepted. This could be due to your credit profile, or because you requested a limit that might overstretch your finances if we were to accept it',
      },
      {
        definition: 'very low possibility',
        copy: '- the information you’ve put into the checker has resulted in us being very unlikely to offer you the arranged overdraft limit you asked for. This could be due to your credit profile',
      },
    ],
    creditScoring: {
      preLinkCopy: 'Find out more about ',
      linkCopy: 'credit scoring',
      link: 'https://www.firstdirect.com/help/useful-information/credit-scoring/',
    },
  },
  applyButton: 'Learn more and apply',
};
