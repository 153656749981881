import React from 'react';
import BrandContext from '../../shared/brandContext';
import { Heading, Callout, Paragraph, Link } from '@piggybank/core';

import { getTealiumPageDefaults } from '../../shared/tealium';
import { renderRepeatedComponent, tealiumEvent } from '../../shared/utils';

const ImportantInformation = () => (
  <BrandContext.Consumer>
    {({ brand }) => {
      const pageContent = brand.content.customerDetails.importantInformation;
      return (
        <Callout type='information' marginBottom={5}>
          <Heading level={3}>{pageContent.heading}</Heading>
          {pageContent.preLinkCopy && renderRepeatedComponent(pageContent.preLinkCopy, Paragraph)}
          <Paragraph>
            {pageContent.privacyPolicyCopy}
            <Link
              onClick={() =>
                tealiumEvent('link', {
                  ...getTealiumPageDefaults(['personal details']),
                  ...{
                    event_action: 'onsite',
                    event_content: 'privacy notice',
                    funnel_step_name: 'personal details',
                  },
                })
              }
              href={pageContent.privacyPolicyURL}
              external
            >
              {pageContent.privacyPolicyLink}
            </Link>
          </Paragraph>
          {pageContent.postLinkCopy && <Paragraph>{pageContent.postLinkCopy}</Paragraph>}
        </Callout>
      );
    }}
  </BrandContext.Consumer>
);

export default ImportantInformation;
