import { IconStatusWarningOndark } from '@piggybank/core';

export default {
  error: {
    icon: IconStatusWarningOndark,
    width: 36,
    style: {
      backgroundColor: '#827e7e',
      border: '2px solid #000000',
      borderRadius: '50%',
    },
  },
};
