import React from 'react';
import { Heading } from '@piggybank/core';
import { Field, FieldFeedback, CurrencyInput, Label, Select } from '@piggybank/form';
import { isEmployedOrSelfEmployed, isZeroAnnualIncome, hasIncome } from '../../shared/utils';
import BrandContext from '../../shared/brandContext';

const payFrequencyLabel = (employmentStatus, payFrequency) =>
  isEmployedOrSelfEmployed(employmentStatus) ? payFrequency.employedLabel : payFrequency.mainIncomeLabel;
const payFrequencyPlaceholder = (annualIncome, payFrequency) =>
  isZeroAnnualIncome(annualIncome) ? payFrequency.zeroIncomePlaceholder : payFrequency.placeholder;

const Income = ({ values, setFieldValue }) => (
  <BrandContext.Consumer>
    {({ brand }) => {
      const income = brand.content.customerDetails.income;
      const lookups = brand.lookups;

      return (
        <>
          <Heading level={2} accentBar>
            {income.heading}
          </Heading>

          <Field
            name='annualIncome'
            onBlur={() => {
              if (values.annualIncome === '0') {
                setFieldValue('payFrequency', '', true);
              }
            }}
          >
            <Label>{income.annualIncome.label}</Label>
            <CurrencyInput symbol='£' maxLength={7} />
            <FieldFeedback />
          </Field>

          {hasIncome(values.employmentStatus) && (
            <Field name='payFrequency'>
              <Label>{payFrequencyLabel(values.employmentStatus, income.payFrequency)}</Label>
              <Select
                options={lookups.payFrequency}
                disabled={isZeroAnnualIncome(values.annualIncome)}
                placeholder={payFrequencyPlaceholder(values.annualIncome, income.payFrequency)}
              />
              <FieldFeedback />
            </Field>
          )}
        </>
      );
    }}
  </BrandContext.Consumer>
);

export default Income;
