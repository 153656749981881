import { IconStatusErrorOnlight } from '@piggybank/core';

export default {
  error: {
    icon: IconStatusErrorOnlight,
    width: 42,
    style: {
      margin: '3px 0px 3px -2px',
      padding: '3px',
      color: '#A8000B',
    },
  },
};
