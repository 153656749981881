import React, { Fragment, useState, useEffect } from 'react';
import {
  Button,
  withContext,
  Heading,
  IconChevronLeft,
  IconChevronRight,
  Section,
  IconMenu,
  IconClose,
} from '@piggybank/core';
import { Hint, WizardContext } from '@piggybank/form';
import './devStyles.css';
import { developmentValues, wizardPages, additionalPages } from './navHelperValues';

let hasPopulated = false;

// Adds navigation to dev branches
const NavHelper = ({ wizardContext, history, handleSubmit }) => {
  const { onPrevious, onNext, navigateTo, isLastPage, currentPage, totalPages } = wizardContext;

  const [menuOpen, setMenu] = useState('closed');
  const [debugMode, setDebug] = useState('no debug');

  useEffect(() => {
    var page = document.getElementById('root');
    page.classList.add('navtools');

    page.classList.toggle('open', menuOpen === 'open');
    page.classList.toggle('debug', debugMode === 'debug');
  });

  const renderMenuIcon = (Component) => (
    <Component width='3em' onClick={() => setMenu(menuOpen === 'open' ? 'closed' : 'open')} />
  );

  return (
    <Fragment>
      <div id='nav-icon'>{renderMenuIcon(menuOpen === 'closed' ? IconMenu : IconClose)}</div>
      <div className={`toolContainer toolContainer--${menuOpen}`}>
        <Hint>Development only, will not be available in Production</Hint>

        <Section marginBottom={0} name='previousNextPanel'>
          <Heading level={3} marginBottom={2}>
            Pages
          </Heading>
          <Button id='prevButton' disabled={currentPage === 0} onClick={() => onPrevious()}>
            <IconChevronLeft inline />
            Previous
          </Button>
          <Button id='nextButton' disabled={isLastPage} onClick={() => onNext()}>
            Next
            <IconChevronRight inline />
          </Button>
        </Section>

        <Section marginBottom={0} name='wizardPagesPanel'>
          {wizardPages.map(function (page) {
            return (
              <Button
                key={`${page.index}-directaccess`}
                onClick={() => {
                  navigateTo(page.index, page.loadData);
                }}
              >
                {page.label}
              </Button>
            );
          })}
        </Section>

        <Section marginBottom={4} name='additionalPagesPanel'>
          {additionalPages.map(function (page) {
            return (
              <Button
                key={page.name}
                onClick={() => {
                  setMenu(menuOpen === 'open' ? 'closed' : 'open');
                  setTimeout(
                    () =>
                      history.push(page.url, {
                        redirection: true,
                        page: page.name,
                      }),
                    250
                  );
                }}
              >
                {page.name}
              </Button>
            );
          })}
        </Section>

        <Section name='debugPanel'>
          <Heading level={3} marginBottom={2}>
            Debug
          </Heading>
          <Button
            onClick={() => {
              document.getElementById('root').classList.toggle('debug');
              setDebug(debugMode === 'debug' ? 'no debug' : 'debug');
            }}
          >
            {debugMode === 'debug' ? 'Hide margins' : 'Show margins'}
          </Button>
          {!hasPopulated && (
            <Button
              onClick={() => {
                navigateTo(!isLastPage ? totalPages - 1 : currentPage, {
                  ...developmentValues,
                });
                hasPopulated = true;
              }}
            >
              Populate
            </Button>
          )}
          <Button
            onClick={() => {
              handleSubmit({ ...developmentValues }, history);
            }}
          >
            Submit
          </Button>
        </Section>
      </div>
    </Fragment>
  );
};

// grab wizard context and pass it to component
export default withContext(WizardContext, (wizardContext) => ({ wizardContext }))(NavHelper);
