import React, { Component } from 'react';
import { Wizard, WizardPage } from '@piggybank/form';
import { Route, Switch } from 'react-router-dom';
import LandingPage from './templates/LandingPage';
import CustomerDetails from './templates/CustomerDetails';
import ApplicationComplete from './templates/ApplicationComplete';
import LoadingPage from './components/LoadingPage';
import DeclinePage from './templates/DeclinePage';
import Timeout from './components/Timeout';
import axios from 'axios';
import { headers } from './shared/headers';
import { request } from './shared/requestBuilder';
import { PageLayout } from '@originations/pattern-library';
import BrandContext from './shared/brandContext';
import { reviewData } from './shared/utils';
import NavHelper from './devTools/NavHelper';
import ErrorPage from './templates/ErrorPage/ErrorPage';
import { tealiumHandleFormSubmit } from './shared/tealium';
import './App.css';

export const handleResponse = (response, history) => {
  switch (response.data.pageAction) {
    case 'DECLINE':
      history.push('/decline', {
        redirection: true,
        page: 'decline',
      });
      break;

    case 'RESULTS':
      history.push('/complete', {
        redirection: true,
        page: 'complete',
      });
      break;

    default:
      history.push('/error', {
        redirection: true,
        page: 'error',
      });
  }
};

class App extends Component {
  state = {
    loading: false,
    timerFinished: false,
    apiResponded: false,
    devMode: false,
  };

  componentDidMount() {
    if (process.env.REACT_APP_PIGGYBANK_THEME === 'mands') {
      require('@piggybank/core/lib/styles.mands.css');
      require('@piggybank/form/lib/styles.mands.css');
    } else if (process.env.REACT_APP_PIGGYBANK_THEME === 'blackbrand') {
      require('@piggybank/core/lib/styles.blackbrand.css');
      require('@piggybank/form/lib/styles.blackbrand.css');
    } else {
      require('@piggybank/core/lib/styles.redbrand.css');
      require('@piggybank/form/lib/styles.redbrand.css');
    }

    process.env.REACT_APP_DEV_MODE === 'true'
      ? this.setState({
          devMode: true,
        })
      : this.setState({
          devMode: false,
        });
  }

  componentDidUpdate() {
    if (this.state.apiResponded && this.state.timerFinished) {
      this.setState({ loading: !this.state.loading });
      this.setState({ responded: !this.state.responded });
      this.setState({ timerFinished: !this.state.timerFinished });
    }
  }

  handleSubmit = (values, history) => {
    this.overdraft = values.overdraft;

    reviewData(values);

    tealiumHandleFormSubmit(values);

    this.setState({ loading: true });

    setTimeout(() => {
      this.setState({ timerFinished: true });
    }, 3000);

    axios({
      url: process.env.REACT_APP_BORROWING_CREDIT_INDICATION,
      method: 'post',
      data: JSON.stringify(request(values)),
      headers: headers,
    })
      .then((response) => {
        this.setState({ apiResponded: true });
        this.apiResponse = response.data;
        handleResponse(response, history);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ apiResponded: true });
        history.push('/error', {
          redirection: true,
          page: 'error',
        });
      });
  };

  render() {
    const content = this.context.brand.content.app;
    return (
      <div className='app'>
        <PageLayout logo={content.logo} footerText={content.footerText}>
          <Timeout>
            <Switch>
              <Route exact path='/'>
                {({ values, history }) => (
                  <Wizard history={history} onSubmit={this.handleSubmit}>
                    {this.state.devMode && <NavHelper history={history} handleSubmit={this.handleSubmit} />}
                    <WizardPage component={LandingPage} />
                    <WizardPage component={CustomerDetails} />
                  </Wizard>
                )}
              </Route>

              <Route
                path='/complete'
                render={(props) => <ApplicationComplete data={this.apiResponse} overdraft={this.overdraft} />}
              />
              <Route path='/decline' render={(props) => <DeclinePage />} />
              <Route path='/error' render={(props) => <ErrorPage />} />
            </Switch>
            <LoadingPage loading={this.state.loading} />
          </Timeout>
        </PageLayout>
      </div>
    );
  }
}

App.contextType = BrandContext;

export default App;
