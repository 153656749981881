export const developmentValues = {
  overdraft: '1000',
  title: 'MR',
  forenames: 'Anthony',
  surname: 'Stark',
  dateOfBirth: '1980-10-20',
  maritalStatus: 'SINGLE',
  dependents: '0',
  employmentStatus: 'FULL',
  recentGraduate: 'yes',
  graduationYear: '2019',
  occupation: 'MANAGER',
  businessType: 'MISCELLANEOUS',
  annualIncome: '75000',
  payFrequency: 'W10',
  hasSavingsOrInvestments: 'yes',
  hasHsbcProduct: 'yes',
  'current-paf-match': false,
  'current-address-line-1': 'First Direct',
  'current-address-line-2': '40 Wakefield Road',
  'current-address-line-3': 'Leeds',
  'current-postcode': 'LS98 1FD',
  residentialStatus: 'OWNER',
  currentDateMoved: '2019-02',
  previousCountry: 'GB',
  'previous-paf-match': false,
  'previous-address-line-1': 'HSBC',
  'previous-address-line-2': '80 Manchester Road',
  'previous-address-line-3': 'Halifax',
  'previous-postcode': 'LS98 1FX',
  previousDateMoved: '2000-02',
};

export const wizardPages = [
  {
    index: 0,
    label: 'Landing Page',
    loadData: [],
  },
  {
    index: 1,
    label: 'Customer Details',
    loadData: [],
  },
];

export const additionalPages = [
  { name: 'Decline', url: '/decline' },
  { name: 'Error', url: '/error' },
];
