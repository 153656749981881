import React from 'react';
import { Heading } from '@piggybank/core';
import { YesNoRadio, FieldFeedback, Fieldset, Legend } from '@piggybank/form';
import BrandContext from '../../shared/brandContext';
import { isAnnualIncomeOver75000 } from '../../shared/utils';

const FinancialDetails = ({ values }) => (
  <BrandContext.Consumer>
    {({ brand }) => {
      const pageContent = brand.content.customerDetails.financialDetails;

      return (
        <>
          <Heading level={2} accentBar>
            {pageContent.heading}
          </Heading>
          <Fieldset name='hasSavingsOrInvestments'>
            <Legend>{pageContent.hasSavingsOrInvestments.label}</Legend>
            <YesNoRadio />
            <FieldFeedback />
          </Fieldset>

          {isAnnualIncomeOver75000(values.annualIncome) && (
            <Fieldset name='hasHsbcProduct'>
              <Legend>{pageContent.hasHsbcProduct.label}</Legend>
              <YesNoRadio />
              <FieldFeedback />
            </Fieldset>
          )}
        </>
      );
    }}
  </BrandContext.Consumer>
);

export default FinancialDetails;
