export default {
  browserTitle: 'Overdraft Eligibility - Results',
  pageTitle: 'Here are your results',
  pageIntro: 'Now you can review the likelihood of you being eligible for an overdraft and apply for an account.',
  overdraft: {
    label: 'Overdraft amount',
    hint: 'Enter your overdraft amount in multiples of £50',
    maxAmount: 3000,
    editButton: 'Edit',
    cancelButton: 'Cancel',
    updateButton: 'Update',
    errorBlank: 'Enter an arranged overdraft amount',
    errorMax: 'Enter an amount of £3,000 or less',
    errorInvalid: 'Overdraft amount must be in multiples of £50',
  },
};
