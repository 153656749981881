import React from 'react';
import PropTypes from 'prop-types';
import { UnorderedList, ListItem } from '@piggybank/core';

const marginBottomLevels = [0, 1, 2, 3, 4, 5, 6, 7, 8];

export const renderRepeatedComponent = (array, Component, marginBottom) =>
  array.map((item, i) => (
    <Component aria-live='assertive' tabIndex='0' key={i} marginBottom={marginBottom}>
      {item}
    </Component>
  ));

export const renderUnorderedList = (array, props) => {
  props = props !== undefined ? props : {};
  return (
    <UnorderedList type={props.type} spacing={props.spacing} marginBottom={props.marginBottom}>
      {renderRepeatedComponent(array, ListItem)}
    </UnorderedList>
  );
};

renderRepeatedComponent.propTypes = {
  array: PropTypes.arrayOf(PropTypes.string).isRequired,
  Component: PropTypes.object.isRequired,
  marginBottom: PropTypes.oneOf(marginBottomLevels),
};

renderUnorderedList.propTypes = {
  array: PropTypes.arrayOf(PropTypes.string).isRequired,
  props: PropTypes.object,
};

export const isEmployedOrUnemployed = (employmentStatus) => ['FULL', 'PART', 'UNEMPLOYED'].includes(employmentStatus);
export const isEmployedOrSelfEmployed = (employmentStatus) => ['FULL', 'PART', 'SELF'].includes(employmentStatus);
export const hasIncome = (employmentStatus) =>
  ['FULL', 'PART', 'SELF', 'PENSION', 'DISABILITY'].includes(employmentStatus);
export const isZeroAnnualIncome = (annualIncome) => annualIncome === '0';
export const isAnnualIncomeOver75000 = (annualIncome) => annualIncome >= 75000;

const isGraduationYearRequired = (employmentStatus, recentGraduate) =>
  isEmployedOrUnemployed(employmentStatus) && recentGraduate === 'yes';
const isPayFrequencyRequired = (employmentStatus, annualIncome) =>
  hasIncome(employmentStatus) && !isZeroAnnualIncome(annualIncome);

const employedOrUnemployedFields = ['recentGraduate', 'graduationYear'];
const employedOrSelfEmployedFields = ['occupation', 'businessType'];

export const reviewData = (values) => {
  if (!isEmployedOrSelfEmployed(values.employmentStatus)) {
    removeFields(values, employedOrSelfEmployedFields);
  }
  if (!isEmployedOrUnemployed(values.employmentStatus)) {
    removeFields(values, employedOrUnemployedFields);
  }
  if (!isGraduationYearRequired(values.employmentStatus, values.recentGraduate)) {
    removeFields(values, ['graduationYear']);
  }
  if (!isPayFrequencyRequired(values.employmentStatus, values.annualIncome)) {
    removeFields(values, ['payFrequency']);
  }
  if (!isAnnualIncomeOver75000(values.annualIncome)) {
    removeFields(values, ['hasHsbcProduct']);
  }

  if (values.employmentStatus !== 'EDUCATION') {
    values.cantFindUcasCode = false;
  }
};

export const removeFields = (values, array) => {
  for (var i = 0; i < array.length; i++) {
    values[array[i]] = undefined;
  }
};

export const currency = (value) => {
  if (value !== undefined) {
    return '£' + value.replace(/\B(?=(\d{3})+(?!\d))/g, '$&,');
  } else {
    return '';
  }
};

export const yesNo = (value) => (value !== undefined ? value === 'yes' : undefined);

export const isUnlikely = (likelihood, content) =>
  [content.definitions.unlikely, content.definitions.leastLikely].includes(likelihood);

export const retrieveAccountDetails = (accountInformation, response) => {
  let accountDetails;
  for (let j = 0; j < accountInformation.length; j++) {
    if (accountInformation[j].accountType === response.productName) {
      accountDetails = accountInformation[j];
    }
  }
  return accountDetails;
};

export const evaluateLikelihood = (requestedOverdraftLimit, response, content) => {
  if (response.offeredOverdraftLimit === 0) {
    return content.definitions.leastLikely;
  }

  if (response.offeredOverdraftLimit < requestedOverdraftLimit) {
    return content.definitions.unlikely;
  }

  return response.systemDecision === 'A1' ? content.definitions.mostLikely : content.definitions.likely;
};

export const renderLikelihoodCopy = (likelihood, response, values, content) => {
  let message;
  let overdraftValue;

  if (likelihood === content.definitions.leastLikely) {
    message = content.likelyCopy;
    overdraftValue = content.extremelyUnlikelyCopy;
  }

  if (likelihood === content.definitions.unlikely) {
    message = content.unlikelyCopy;
    overdraftValue = currency(response.offeredOverdraftLimit.toString());
  }

  if ([content.definitions.likely, content.definitions.mostLikely].includes(likelihood)) {
    message = content.likelyCopy;
    overdraftValue = currency(values.adjustedOverdraftLimit !== '' ? values.adjustedOverdraftLimit : '0');
  }

  return `${message} ${overdraftValue}`;
};

export const renderLikelihoodDefinitions = ({ definition, copy }) => {
  if (definition === undefined || copy === undefined) {
    throw new Error('Invalid likelihood definition');
  }

  return (
    <div>
      <b>{definition}</b> {copy}
    </div>
  );
};

export const forceFocusToLink = (link) => setTimeout(() => link.focus(), 215);

export const tealiumEvent = (component, tealiumObject) => {
  const tms = window.TMS;
  if (tms) {
    tms.trackEvent(component, tealiumObject);
  } else {
    console.warn('TMS is not available'); // eslint-disable-line no-console
  }
};

export const tealiumView = (tealiumObject) => {
  const tms = window.TMS;
  if (tms) {
    tms.trackView(tealiumObject);
  } else {
    console.warn('TMS is not available'); // eslint-disable-line no-console
  }
};
