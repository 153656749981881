export default {
  definitions: {
    mostLikely: 'Highly likely',
    likely: 'Likely',
    unlikely: 'Unlikely',
    leastLikely: 'Extremely unlikely',
  },
  likelyCopy: 'to be eligible for',
  unlikelyCopy: 'reduce amount to less than',
  extremelyUnlikelyCopy: 'an arranged overdraft limit',
  basicAccountCopy:
    'Please note, lending facilities are not available (eg overdrafts, credit cards, personal loans or mortgages)',
  accountBenefitsHeading: 'Account benefits',
  accountBenefitsCopy: 'Subject to further eligibility checks.',
  likelihoodModal: {
    preLink: 'What does',
    postLink: 'mean?',
    title: 'What do these results mean?',
    copy: [
      'These results tell you how likely you are to be offered your preferred overdraft limit should you choose to apply.',
      'The full range of possible results of an eligibility check are:',
    ],
    definitions: [
      {
        definition: 'Highly likely',
        copy: 'to be eligible for an arranged overdraft limit',
      },
      {
        definition: 'Likely',
        copy: 'to be eligible for an arranged overdraft limit',
      },
      {
        definition: 'Unlikely',
        copy: 'to be eligible for an arranged overdraft limit',
      },
      {
        definition: 'Extremely unlikely',
        copy: 'to be eligible for an arranged overdraft limit',
      },
    ],
  },
  accountCriteriaModal: {
    link: 'Account criteria',
    preLink: 'Is an HSBC',
    postLink: 'right for me?',
  },
  applyButton: 'Learn more and apply',
};
