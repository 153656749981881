export default {
  browserTitle: 'Overdraft Eligibility - Personal details ',
  pageTitle: 'Check your overdraft eligibility',
  pageIntro: 'All questions are mandatory unless indicated.  This will have no impact on your credit rating.',
  overdraft: {
    heading: 'Overdraft amount',
    maxAmount: 5000,
    trackLabels: {
      0: '£0',
      5000: '£5,000',
    },
    label: 'What arranged overdraft would you like to be considered for (maximum £5,000)?',
    hint: 'Enter your overdraft amount in multiples of £50',
    errorRequired: 'Enter an arranged overdraft amount',
    errorMax: 'Enter an amount of £5,000 or less',
    errorInvalid: 'Overdraft amount must be in multiples of £50',
  },
  personalDetails: {
    heading: { copy: 'Personal Details', accentBar: true },
    title: {
      label: 'Title',
      errorBlank: 'Select your title',
    },
    forenames: {
      label: 'First names',
      hint: 'As they appear on your identity documents including any middle names.',
      errorBlank: 'Enter all first names and any middle names you have',
      errorMin: 'Your first names and middle names must have at least 2 characters',
      errorInvalid: "Enter your first names and middle names using only the characters a-z, - and '",
    },
    surname: {
      label: 'Surname or Family name',
      hint: 'As it appears on your identity documents.',
      errorBlank: 'Enter surname or family name',
      errorMin: 'Your surname or family name must have at least 2 characters',
      errorInvalid: "Enter your surname or family name using only the characters a-z, - and '",
    },
    dateOfBirth: {
      label: 'What is your date of birth?',
      errorBlank: 'Enter your date of birth',
      errorAboveMaxAge: 'Check your date of birth is correct',
      errorBelowMinAge: "If you're under 18 we can't offer you this product",
      errorInvalid: 'Enter your date of birth in the format DD MM YYYY',
    },
    maritalStatus: {
      label: 'Marital status',
      errorBlank: 'Select your marital status',
    },
    dependents: {
      label: 'Number of dependent children',
      hint: 'This helps us to understand your personal circumstances.',
      errorBlank: 'Dependent children must be a number between 0 and 99',
    },
  },
  address: {
    heading: {
      copy: 'Current UK residential address',
      accentBar: true,
    },
    residentialStatus: {
      label: 'Residential status',
      errorBlank: 'Select your residential status',
    },
    currentDateMoved: {
      label: 'When did you move to this address?',
      hint: "We need to understand where you've lived for the past 3 years",
      errors: {
        blank: 'Enter the date you moved to this address in the format MM YYYY',
        invalid: 'Enter the date you moved to this address in the format MM YYYY',
        dob: "You've entered a move date that's before your birth date.  Check and re-enter",
        min: 'Check the date is correct',
        max: "The date you've entered is in the future, please amend it",
      },
    },
    previousDateMoved: {
      label: 'When did you move to this address?',
      errors: {
        blank: 'Enter the date you moved to this address in the format MM YYYY',
        invalid: 'Enter the date you moved to this address in the format MM YYYY',
        dob: "You've entered a move date that's before your birth date.  Check and re-enter",
        current: "You've entered a move date that's after your current address.  Check and re-enter",
        min: 'Check the date is correct',
        max: "The date you've entered is in the future, please amend it",
      },
    },
    previousAddress: 'Where did you live before',
    previousCountry: 'Country / region',
    current: {
      search: {
        label: 'Find your UK address',
        hint: 'Enter your address or postcode',
      },
      errors: {
        noResults:
          "We can't find that address. Please check it and try again. If it still doesn't appear, enter it manually",
        noService: "Sorry, we can't look up addresses at the moment. Please enter it manually",
        blank: 'Enter your current address',
        'current-address-line-1': {
          blank: 'Enter your building number or name',
          invalid: 'Enter your building number or name using only alphanumeric characters and basic punctuation',
        },
        'current-address-line-2': {
          blank: 'Enter your street name',
          invalid: 'Enter street using only alphanumeric characters and basic punctuation',
        },
        'current-address-line-3': {
          blank: 'Enter your town or city',
          invalid: 'Enter town or city using only alphanumeric characters and basic punctuation',
        },
        'current-postcode': {
          blank: 'Enter your postcode',
          format: 'Check your postcode is correct',
          ciiom: 'We are not able to accept applications from the Channel Islands and the Isle of Man',
        },
      },
      enterManually: 'Enter your address manually',
      editButton: {
        label: 'Edit',
        hidden: 'your current address',
      },
      addressLabel: 'Your current address',
      fields: {
        line1: 'Building number or name',
        line2: 'Street',
        line3: 'Town or city',
        postcode: 'Postcode',
      },
    },
    previousUk: {
      search: {
        label: 'Find your address',
        hint: 'Enter your address or postcode',
        errors: {
          blank: 'Enter the date you moved to this address in the format MM YYYY',
          invalid: 'Enter the date you moved to this address in the format MM YYYY',
          dob: "You've entered a move date that's before your birth date.  Check and re-enter",
          min: 'Check the date is correct',
          max: "The date you've entered is in the future, please amend it",
        },
      },
      errors: {
        noResults:
          "We can't find that address. Please check it and try again. If it still doesn't appear, enter it manually",
        noService: "Sorry, we can't look up addresses at the moment. Please enter it manually",
        blank: 'Enter your previous address',
        'previous-address-line-1': {
          blank: 'Enter your building number or name',
          invalid: 'Enter your building number or name using only alphanumeric characters and basic punctuation',
        },
        'previous-address-line-2': {
          blank: 'Enter your street name',
          invalid: 'Enter street using only alphanumeric characters and basic punctuation',
        },
        'previous-address-line-3': {
          blank: 'Enter your town or city',
          invalid: 'Enter town or city using only alphanumeric characters and basic punctuation',
        },
        'previous-postcode': {
          blank: 'Enter your postcode',
          format: 'Check your postcode is correct',
        },
      },
      enterManually: 'Enter your address manually',
      editButton: {
        label: 'Edit',
        hidden: 'your previous address',
      },
      addressLabel: 'Your previous address',
      fields: {
        line1: 'Building number or name',
        line2: 'Street',
        line3: 'Town or city',
        postcode: 'Postcode',
      },
    },
    previousOverseas: [
      {
        label: 'Address line 1',
        errors: {
          blank: 'Enter address Line 1',
          invalid: 'Enter address line 1 using only alphanumeric characters and basic punctuation',
        },
      },
      {
        label: 'Address line 2',
        errors: {
          blank: 'Enter address Line 2',
          invalid: 'Enter address line 2 using only alphanumeric characters and basic punctuation',
        },
      },
      {
        label: 'Address line 3 (optional)',
        errors: {
          invalid: 'Enter address line 3 using only alphanumeric characters and basic punctuation',
        },
      },
      {
        label: 'Address line 4 (optional)',
        errors: {
          invalid: 'Enter address line 4 using only alphanumeric characters and basic punctuation',
        },
      },
      {
        label: 'Address line 5 (optional)',
        errors: {
          invalid: 'Enter address line 5 using only alphanumeric characters and basic punctuation',
        },
      },
    ],
  },
  employment: {
    heading: 'Employment',
    employmentStatus: {
      label: 'Select an option that best describes your status',
      errorBlank: 'Select your employment status',
    },
    occupation: {
      label: 'Occupation',
      errorBlank: 'Select your occupation',
    },
    businessType: {
      label: 'Business type',
      errorBlank: 'Select a business type',
    },
    recentGraduate: {
      label: 'Have you graduated from university in the last two years?',
    },
    graduationYear: {
      label: 'Which year did you graduate?',
      errorBlank: 'Enter the year you graduated in the format YYYY',
      errorMin: 'Your graduation must have been within the last 2 years',
      errorMax: "The graduation date you've entered is in the future, please amend it",
    },
    ucas: {
      question: 'University or further education provider',
      checkbox: 'University or further education provider not listed',
      unknown: 'University or further education provider not listed',
      errorBlank: 'Tell us your University or further education provider',
    },
  },
  income: {
    heading: 'Income',
    annualIncome: {
      label: 'Total annual income (before tax and other deductions)',
      errorBlank: 'Enter your annual income',
    },
    payFrequency: {
      placeholder: 'Please select',
      zeroIncomePlaceholder: 'N/A',
      employedLabel: 'How often are you paid?',
      employedErrorBlank: "Select how often you're paid",
      mainIncomeLabel: 'How often do you receive your main income?',
      mainIncomeErrorBlank: 'Select how often you receive your main income',
    },
  },
  financialDetails: {
    heading: 'Financial Details',
    hasSavingsOrInvestments: {
      label: 'Do you expect to have at least £50,000 of savings or investments with HSBC UK in the next 6 months? ',
      errorBlank:
        'Tell us if you expect to have at least £50,000 of savings or investments with HSBC UK in the next 6 months',
    },
    hasHsbcProduct: {
      label: 'Do you currently have a mortgage, an investment or an insurance product with HSBC UK?',
      errorBlank: 'Tell us if you currently have a mortgage, an investment or an insurance product with HSBC UK',
    },
  },
  importantInformation: {
    heading: 'How we use your personal information',
    privacyPolicyCopy:
      'We will process your personal information in order to provide an indication of whether you are likely to be accepted for an overdraft and to monitor the performance and the accuracy of the Eligibility Checker. Your data will be kept confidential and used in accordance with our ',
    privacyPolicyLink: 'Privacy Notice.',
    privacyPolicyURL: 'https://www.hsbc.co.uk/content/dam/hsbc/gb/pdf/privacy-notice2.pdf',
    postLinkCopy: 'This will not impact your credit rating.',
  },
  ctaPrevious: 'Back',
  ctaSubmit: 'Check your eligibility',
};
