import { yesNo } from './utils';

export const request = (values) => {
  return {
    requestedOverdraftAmount: values.overdraft,
    primaryApplicant: {
      person: {
        title: values.title,
        forename: values.forenames.trim(),
        surname: values.surname.trim(),
        dateOfBirth: values.dateOfBirth,
        maritalStatusCode: values.maritalStatus,
        numberOfChildren: values.dependents,
      },
      contactDetails: {
        currentAddress: {
          buildingNameOrNumber: values['current-address-line-1'].trim(),
          street: values['current-address-line-2'].trim(),
          townOrCity: values['current-address-line-3'].trim(),
          postCode: values['current-postcode'].trim(),
          countryCode: 'GB',
          dateOccupied: values.currentDateMoved + '-01',
          isPcaAddress: values['current-paf-match'],
        },
        residentialStatusCode: values.residentialStatus,
        previousAddress: previousAddress(values),
      },
      employmentDetails: {
        employmentStatusCode: values.employmentStatus,
        occupationCode: values.occupation,
        employersBusinessType: values.businessType,
        graduate: values.recentGraduate === 'yes',
        graduationYear: values.graduationYear,
        ucasCode: optional(values.ucasCode),
      },
      financialDetails: {
        annualIncome: values.annualIncome,
        frequencyOfIncomePayment: values.payFrequency,
        hasSavingsOrInvestments: yesNo(values.hasSavingsOrInvestments),
        hasHsbcProduct: yesNo(values.hasHsbcProduct),
      },
    },
  };
};

export const previousAddress = (values) => {
  if (values['previous-address-line-1']) {
    if (values.previousCountry === 'GB') {
      return {
        addressFormat: 'STRUCTURED',
        structuredAddress: {
          buildingNameOrNumber: values['previous-address-line-1'].trim(),
          street: values['previous-address-line-2'].trim(),
          townOrCity: values['previous-address-line-3'].trim(),
          postCode: values['previous-postcode'].trim(),
          countryCode: values.previousCountry,
          dateOccupied: values.previousDateMoved + '-01',
          isPcaAddress: values['previous-paf-match'],
        },
      };
    } else {
      return {
        addressFormat: 'UNSTRUCTURED',
        unstructuredAddress: {
          addressLine1: values['previous-address-line-1'],
          addressLine2: values['previous-address-line-2'],
          addressLine3: optional(values['previous-address-line-3']),
          addressLine4: optional(values['previous-address-line-4']),
          addressLine5: optional(values['previous-address-line-5']),
          countryCode: values.previousCountry,
          dateOccupied: values.previousDateMoved + '-01',
        },
      };
    }
  } else {
    return undefined;
  }
};

export const optional = (value) => {
  return value ? value : undefined;
};
