export default {
  browserTitle: 'Overdraft Eligibility - Decline',
  pageTitle: "Sorry, we can't offer you an arranged overdraft",
  copy: [
    'We based our result on the information you gave us and information we received from credit references agencies Experian, Equifax and CallCredit.',
  ],
  section: {
    heading: 'What can I do now?',
    copy: 'For more information on how we assess credit applications, and contact details for the credit reference agencies,  see our ',
    creditScoringCopy: 'credit scoring guide.',
    creditScoringLink: 'https://www.firstdirect.com/help/useful-information/credit-scoring/',
  },
  button: 'Go to first direct website',
  url: 'https://www.firstdirect.com/',
};
