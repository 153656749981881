export default {
  countdown: {
    title: 'Need more time?',
    copy: [
      'For your security, the checker will timeout if the screen has been inactive for a while.',
      "Your session is due to timeout shortly. If it does, we'll remove all the personal details you entered.",
    ],
    timeRemainingBefore: 'Time remaining:',
    timeRemainingAfter: 'seconds',
    buttonText: 'Continue eligibility check',
  },
  timedOut: {
    browserTitle: 'Overdraft Eligibility - Timeout',
    heading: 'Timeout',
    copy: [
      'For your security, you’ve been timed out.',
      'The checker will timeout if the screen has been inactive for a while.',
      "We've removed all the personal details you entered.",
    ],
    buttonText: 'Start again',
  },
};
